import { settings } from './settings';
import { db } from './db';

import { toast } from './toast';

import localforage from 'localforage/dist/localforage.min.js';

import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

var auth = function () {
	return {
		init: function () {},
		setToken: function (token) {
			if (token) {
				settings.token = token;
			}
		},
		getToken: function (username = false, password = false, success = false, failure = false) {
			return new Promise((resolve, reject) => {
				if (username && password) {
					toast().show('Iniciando Sesión', 'warning', 2000);
					fetch(settings.restUrl + '/token?username=' + username, {
						headers: {
							'Authorization': 'Basic ' + btoa(username + ':' + password)
						}
					})
						.then((response) => {
							return response.json();
						})
						.then((response) => {
							if (response.class == 'success') {
								localforage.setItem('access', response.info);

								// Set Token
								auth().setToken(response.info.token);

								// Retrieve Member and Applications and set Initial Trip Id
								db().getMember(response.member_id).then(function () {
									if (success) {
										success(response);
									}
								});
							} else {
								toast().show(response.html, response.class);
								console.log(response);

								if (failure) {
									failure(response);
								}
							}
						})
						.catch(error => {
							console.log(error);
							if (failure) {
								failure();
							}
							reject(error);
						});
				}
			});
		},
		checkToken: function () {
			return new Promise((resolve, reject) => {
				localforage.getItem('access').then(function (table) {
					if (table && 'token' in table) {
						if (
							moment().isBefore(table.expiration)
						) {
							// Set Token
							auth().setToken(table.token);

							resolve(table.token);
						} else {
							auth().deleteToken();
							reject('Token Vencido');
						}

					} else {
						auth().deleteToken();
						reject('Token Inexistente');
					}
				}).catch(function (error) {
					if (App.current() != 'login') App.load('login');
					reject(error);
				});
			});
		},
		deleteToken: function () {
			fetch(settings.restUrl + '/logout?token=' + settings.token + '&member_id=' + settings.member.member_id)
				.then(() => {
					localforage.clear();
					if (App.current() != 'login') App.load('login');
				});

		},
		deleteAll: function () {
			localforage.clear();

			if ('serviceWorker' in navigator) {
				caches.delete(settings.version);
				navigator.serviceWorker
					.getRegistrations()
					.then(function (registrations) {
						for (let registration of registrations) {
							registration.unregister();
						}
						window.location.replace(window.location.href);
					});
			}
		},
		recoverPassword: function (username = false, success = false, failure = false) {
			if (username) {
				fetch(settings.restUrl + '/recover?username=' + username)
					.then((response) => {
						return response.json();
					})
					.then((response) => {
						toast().show(response.html, response.class);

						if (response.class == 'success') {
							if (success) {
								success(response);
							}
						} else {
							if (failure) {
								failure(response);
							}
						}
					});
			}
		},

		updateSetting: function (name, value) {
			if (name in settings) settings[name] = value;
		}
	};
};

export { auth };