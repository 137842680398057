import { settings } from '../libraries/settings';
import { auth } from '../libraries/auth';
import { db } from '../libraries/db';

import { tools, templating, hideLoader } from '../libraries/tools';
import { toast } from '../libraries/toast';
import { process } from '../libraries/process';

import gtag from 'ga-gtag';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

import localforage from 'localforage/dist/localforage.min.js';

import 'flickity/dist/flickity.min.css';
import Flickity from 'flickity';
import PullToRefresh from 'pulltorefreshjs';

App.controller('application', function (page, data) {
	auth().checkToken();
	page.addEventListener('appReady', function () {
		gtag('event', 'screen_view', { screen_name: 'Viaje' });
		tools().setPexelBackground('.section-hero');

		// Install Dialog
		var pwaInstall = document.getElementsByTagName('pwa-install')[0];
		localforage.getItem('installDialogHidden').then(function (value) {
			if (value === null) {
				pwaInstall.showDialog();
				localforage.setItem('installDialogHidden', true);
			}
		});

		if (!pwaInstall.isUnderStandaloneMode) {
			page.querySelector('.button-install').addEventListener('click', function () {
				pwaInstall.showDialog();
			});
		} else {
			page.querySelector('.button-install').remove();
		}

		// Get Trip
		let tripId = data.tripId || false;
		db().getTrip(tripId).then(function (data) {

			hideLoader(page.querySelector('.app-loader-w'));

			if (data) {
				db().getMember().then(function (member) {
					let application = data.application;
					let step = data.step;

					tools().showSupportNav(page);

					// Headers
					page.querySelector('.member-name').innerHTML = application.member_nickname || application.member_name;
					page.querySelector('.step-name').innerHTML = step.name;
					page.querySelector('.application-program').prepend(application.program_label);
					page.querySelector('.application-subprogram').prepend(application.subprogram_label);

					if (application.member_avatar) {
						let avatar_url = [settings.contentUrl, 'participants', application.member_id, application.member_avatar].join('/');
						if (tripId) avatar_url += '?' + moment().format('X');
						page.querySelector('.step-header-avatar').style.backgroundImage = `url(${avatar_url})`;
					} else {
						page.querySelector('.step-header-avatar').remove();
					}

					if (application.enrollment) {
						var enrollmentDate = moment(application.enrollment);
						page.querySelector('.application-enrollment-wrap').classList.remove('d-none');
						page.querySelector('.application-enrollment').innerHTML = enrollmentDate.format('D [de] MMMM, YYYY');
					} else {
						page.querySelector('.application-enrollment-wrap').remove();
					}

					// Relations
					let membersRelationsElement = page.querySelector('.member-relations');
					if (application.relations.length > 0) {
						console.log(application.relations);
						membersRelationsElement.innerHTML = '';

						Object.values(application.relations).forEach((row) => {
							let item = document.createElement('div');
							item.className = 'app-avatar rounded-circle d-flex justify-content-center align-items-center flex-shrink-0';
							item.setAttribute('data-name', row.relation_nickname);

							if (row.relation_avatar) {
								let relation_url = [settings.contentUrl, 'participants', row.relation_member_id, row.relation_avatar].join('/');
								let img = new Image();

								item.innerHTML = '<i class="material-icons">hourglass_empty</i>';
								img.onload = function () {
									item.innerHTML = '';
									item.style.backgroundImage = `url(${img.src})`;
								};
								img.src = relation_url;
							}

							membersRelationsElement.append(item);
						});
					} else {
						membersRelationsElement.remove();
					}

					// Near Destination
					if (application.near_destination.length > 0) {
						Object.values(application.near_destination).forEach((row) => {
							let item = document.createElement('div');
							item.className = 'app-avatar rounded-circle border-dark d-flex justify-content-center align-items-center flex-shrink-0';
							item.setAttribute('data-name', row.relation_nickname);

							if (row.relation_avatar) {
								let relation_url = [settings.contentUrl, 'participants', row.relation_member_id, row.relation_avatar].join('/');
								let img = new Image();

								item.innerHTML = '<i class="material-icons">hourglass_empty</i>';
								img.onload = function () {
									item.innerHTML = '';
									item.style.backgroundImage = `url(${img.src})`;
								};
								img.src = relation_url;
							}
							/*
							if (row.relation_phone) {
								item.addEventListener('click', function () {
									window.open('whatsapp://send?phone=' + ((/^54/.test(row.relation_phone) === false) ? 549 : '') + row.relation_phone, '_system', 'hidden=yes');
								});
							}
							*/

							if (row.instagram) {
								let instagramHandler = row.instagram;
								if (instagramHandler.startsWith('@')) {
									instagramHandler = instagramHandler.slice(1);
								}
								item.addEventListener('click', function () {
									window.open('https://instagram.com/' + instagramHandler, '_system', 'hidden=yes');
								});

								item.classList.add('cursor-pointer');
							}

							page.querySelector('.member-near-destination').append(item);
						});
					} else {
						page.querySelector('.member-near-destination-wrap').remove();
					}


					// Tournament
					if (application.year === 2024 && [1, 2].includes(application.subprogram_id)) {
						document.querySelectorAll('.links-copa').forEach(el => el.classList.remove('d-none'));
					} else {
						document.querySelectorAll('.links-copa').forEach(el => el.remove());
					}

					// Step
					let nodeItem = document.querySelector('#node-action-item');
					switch (data.step.type) {
						case 'form':
							if (step.details) {
								page.querySelector('.step-details .section-body').innerHTML = templating(step.details, {
									application: application,
									member: member
								});
							} else {
								page.querySelector('.step-details').remove();
							}

							let formWrap = document.createElement('div');
							formWrap.classList.add('form-wrap', 'mt-5');
							formWrap.formJ(data.step.form_fields, { id: data.step.form_name, classes: 'rounded pt-1 bg-white' });
							page.querySelector('.step-actions-payments').before(formWrap);

							page.querySelectorAll(".step-documents, .step-actions-todo-list-title, .step-actions-todo-list, .step-actions-done-list")
								.forEach(el => el.remove());
							break;
						case 'content':
							if (step.details) {
								page.querySelector('.step-details .section-body').innerHTML = templating(step.details, {
									application: application,
									member: member
								});
							} else {
								page.querySelector('.step-details').remove();
							}
							page.querySelectorAll(".step-documents, .step-actions-todo-list-title, .step-actions-todo-list, .step-actions-done-list")
								.forEach(el => el.remove());
							break;
						case 'contenttasks':
							if (step.details) {
								page.querySelector('.step-details .section-body').innerHTML = templating(step.details, {
									application: application,
									member: member
								});
							} else {
								page.querySelector('.step-details').remove();
							}

						case 'tasks':
							if (data.step.type === 'tasks') page.querySelector('.step-details').remove();

							// Actions
							let listActionsToDo = page.querySelector(".step-actions-todo-list");
							if (data.todo && Object.keys(data.todo).length > 0) {

								Object.values(data.todo).forEach((row) => {
									process().filterAction(row.filters).then(function (result) {
										if (result) {
											let item = nodeItem.content.cloneNode(true);
											item.firstElementChild.classList.add('step-todo');
											if (application.enrollment) row.enrollment = application.enrollment;

											listActionsToDo.append(process().formatAction(row, item));
										}
									});
								});
							} else {
								page.querySelector('.step-actions-todo-list-title').remove();
								listActionsToDo.remove();
							}


							let listActionsSticky = page.querySelector(".step-actions-sticky-list");
							let nodeStickyItem = document.querySelector('#node-action-sticky');
							let listActionsDone = page.querySelector(".step-actions-done-list");
							let totalActionsDone = Object.keys(data.done).length;
							let countActions = 0;
							var countActionsSticky = 0;

							if (data.done && totalActionsDone > 0) {
								Object.values(data.done).forEach((row) => {
									process().filterAction(row.filters)
										.then(function (result) {
											if (result) {
												if (application.enrollment) row.enrollment = application.enrollment;

												if (row.sticky === 1) {
													row.keepIcon = true;
													let item = nodeStickyItem.content.cloneNode(true);
													listActionsSticky.append(process().formatAction(row, item));

													// Save to Cache
													if ('files' in row && row.files.length > 0) {
														caches.open(settings.version).then((cache) => {
															row.files.forEach((file) => cache.add(file));
														});
													}

													countActionsSticky++;
												} else {
													let item = nodeItem.content.cloneNode(true);
													item.firstElementChild.classList.add('step-done');
													item.querySelectorAll('.action-checkbox, .action-legend')
														.forEach(el => el.remove());

													listActionsDone.append(process().formatAction(row, item));
												}
											} else {
												countActions++;
											}
										})
										.then(function () {
											countActions++;

											page.querySelector('.button-show-done .q').innerHTML = countActions;
											if (countActions === totalActionsDone) {
												if (countActionsSticky === 0) {
													page.querySelector(".step-documents").remove();
												} else {
													new Flickity(listActionsSticky, {
														prevNextButtons: false,
														pageDots: false,
														contain: true,
														cellAlign: 'left',
														dragThreshold: 10,
														draggable: '>1',
														friction: 0.3
													});
												}
											}
										});
								});

								page.querySelector('.button-show-done').addEventListener('click', function (e) {
									const status = e.currentTarget.dataset.status;
									if (status === 'open') {
										this.setAttribute('data-status', 'closed');
										this.querySelector('i').innerHTML = 'expand_more';
									} else {
										this.setAttribute('status', 'open');
										this.querySelector('i').innerHTML = 'expand_less';
									}
									listActionsDone.classList.toggle('hide-items');
								});
							} else {
								listActionsDone.remove();
								page.querySelector(".step-documents").remove();
							}
							break;
					}

					// Payments
					let titlePayments = page.querySelector(".step-actions-payments-title");
					let listPayments = page.querySelector(".step-actions-payments-list");
					let nodePaymentItem = document.querySelector('#node-payment-item');

					let stepPayments = page.querySelector('.step-payments');
					stepPayments.innerHTML = templating(stepPayments.innerHTML, {
						payments: {
							price: application.price,
							paid: application.paid + application.discounted,
							balance: Math.round(application.price - application.paid - application.discounted)
						}
					});

					if (data.payments.length > 0) {
						Object.values(data.payments).forEach((row) => {
							let item = nodePaymentItem.content.cloneNode(true);
							let isReturn = row.component == 'return';
							let actionIconElement = item.querySelector(".action-icon");
							let actionIconIElement = item.querySelector(".action-icon i");

							if (row.eta > 0) {
								actionIconElement.classList.add('border-green');
								actionIconIElement.innerHTML = isReturn ? 'replay' : 'receipt';
								actionIconIElement.classList.add("text-green");
							} else {
								actionIconElement.classList.add('border-yellow');
								actionIconIElement.innerHTML = 'hourglass_empty';
								actionIconIElement.classList.add("text-yellow");
							}

							item.querySelector('.action-title').innerHTML = 'U$D ' + row.gamma + (isReturn ? ' devueltos por ' : ' pagados con ') + row.method_label;
							item.querySelector('.action-details').innerHTML = 'Pagado el ' + moment(row.date).format('DD [de] MMMM [del] YYYY') + ' en concepto de ' + row.note;

							item.querySelectorAll('.action-legend, .action-checkbox').forEach(el => el.remove());

							listPayments.append(item);
						});
					} else {
						listPayments.innerHTML = '<li class="text-muted ms-3">Todavía no tenés pagos cargados</li>';
					}

					if (data.step.payment) {
						page.querySelector('.button-payment-info').addEventListener('click', function () {
							App.load('reader', {
								title: 'Formas de Pago ',
								content: data.step.payment
							});
						});
					} else {
						page.querySelector('.button-payment-info').parentNode().remove();
					}

					// Terms
					if (step.terms) {
						page.querySelector('.step-terms')
							.setAttribute("data-target-args", JSON.stringify({
								title: 'Condiciones del paso: ' + step.name,
								content: step.terms
							}));
					} else {
						page.querySelector('.step-terms').remove();
					}
				});

				// Content
				let listContent = page.querySelector('.content-gallery');
				var fetchPostsHeaders = new Headers();
				fetchPostsHeaders.append('pragma', 'no-cache');
				fetchPostsHeaders.append('cache-control', 'no-cache');
				fetch('https://wetag.ar/wp-json/wp/v2/posts?categories=21&posts_per_page=10&_embed&_' + new Date().getTime())
					.then((response) => {
						return response.json();
					})
					.then((posts) => {
						listContent.innerHTML = '';
						if (posts.length > 0) {
							posts.forEach((post) => {
								let coverSizes = '_embedded' in post && 'wp:featuredmedia' in post._embedded ? post._embedded['wp:featuredmedia'][0].media_details.sizes : false;
								let cover = false;
								if (coverSizes) {
									if ('medium' in coverSizes) {
										cover = coverSizes.medium.source_url;
									} else {
										cover = coverSizes.full.source_url;
									}
								}

								let item = document.createElement('div');
								item.className = 'post drop-shadow-dark-left';
								item.innerHTML = '<h3 class="m-0 p-2 text-white">' + post.title.rendered + '</h3>';

								if (cover) item.style.backgroundImage = `url(${cover})`;

								item.setAttribute('data-args', JSON.stringify({
									title: post.title.rendered,
									excerpt: post.excerpt.rendered,
									content: post.content.rendered,
									date: post.modified,
									cover: cover,
									acf: post.acf
								}));

								listContent.append(item);
							});
						}
					})
					.then(function () {
						var flkty = new Flickity(listContent, {
							prevNextButtons: false,
							pageDots: false,
							cellAlign: 'left',
							contain: true,
							dragThreshold: 10,
							friction: 0.3
						});
						flkty.on('staticClick', function (event, pointer, element) {
							App.load('reader', JSON.parse(element.dataset.args))
						});

					})
					.catch(error => {
						console.log(error);
					});
			}

		});

		// Pull to Refresh
		localforage.getItem('trip').then(function (table) {
			if (table) {
				let expired = table ? moment().diff(table.timestamp, 'minutes') > 1 : true;
				page.querySelector('.button-refresh-time ').innerHTML = 'actualizado ' + moment(table.timestamp).fromNow();
			}
		});

		PullToRefresh.init({
			mainElement: '.app-content',
			triggerElement: '.page-application',
			instructionsPullToRefresh: 'Arrastrá hacia abajo para actualizar',
			instructionsReleaseToRefresh: 'Soltá para actualizar',
			instructionsRefreshing: 'Actualizando',
			onRefresh() {
				App.load('application', { tripId: db().getCurrentTripId() });
			},
			shouldPullToRefresh: function () {
				return !this.mainElement.scrollTop;
			}
		});


		page.querySelector('.button-refresh').addEventListener('click', function () {
			App.load('application', { tripId: db().getCurrentTripId() });

		});
	});
});
