import { settings, videoTutorialOptions } from '../libraries/settings';
import { toast } from '../libraries/toast';
import { db } from '../libraries/db';
import { hideLoader } from '../libraries/tools';

import YouTubePlayer from 'youtube-player';
import StarRating from 'star-rating.js'
import '/src/assets/css/star-rating.css';

App.controller('upload-form', function (page, data) {
    this.restorable = false;

    page.querySelector('.upload-title').append(data.name);
    if (data.instructions) {
        page.querySelector(".upload-details").innerHTML = data.instructions;
    } else {
        page.querySelector(".upload-details").remove();
    }
    // Parse Fields & Values
    if (data.fields) {
        let fields = {};
        Object.values(data.fields).forEach(function (options) {
            fields["filecontent[" + options.name + "]"] = options;
        });
        page.querySelector(".form-wrap").formJ(fields, { classes: 'rounded pt-2 bg-white' });

        /*
        let filecontent = {};
        if (data.filecontent) {
          $.each(data.filecontent, function(name, options) {
            filecontent["filecontent[" + name + "]"] = options;
          });
          //page.querySelector('.form-wrap').fill(filecontent);
        }
        */
    }

    page.addEventListener('appReady', function () {
        // Photo Example
        if (data.example) {
            page.querySelector(".upload-example")
                .innerHTML = '<img src="' + settings.contentUrl + '/others/actions/' + data.example + '" class="img-fluid rounded">';
        } else {
            page.querySelector(".upload-example").remove();
        }

        // Video Tutorial
        if (data.video) {
            let player = YouTubePlayer("upload-video", Object.assign({
                videoId: data.video,
                width: page.querySelector("#upload-video").width()
            }, videoTutorialOptions));

            page.querySelector(".upload-video").style.visibility = 'visible';
        } else {
            page.querySelector(".upload-video").remove();
        }

        // Rating
        let ratingStarsFields = page.querySelectorAll('.rating-stars');
        if (ratingStarsFields) {
            ratingStarsFields.forEach((el) => {
                new StarRating(el, {
                    tooltip: false
                });
            });
        }

        let ratingEmotionsFields = page.querySelectorAll('.rating-emotions');
        if (ratingEmotionsFields) {
            ratingEmotionsFields.forEach((el) => {
                new StarRating(el, {
                    prebuilt: true,
                    maxStars: 5,
                    tooltip: false
                });
            });
        }

        hideLoader(page.querySelector('.app-loader'));

        let isUploading = false;
        page.querySelector(".upload-step-form")
            .addEventListener("click", function () {
                if (!isUploading) page.querySelector(".form-form").dispatchEvent(new Event('submit'));
            });

        page.querySelector(".form-form")
            .addEventListener("submit", function (e) {
                if (isUploading) return false;
                isUploading = true;

                e.preventDefault();
                e.stopPropagation();

                page.classList.add('app-block-touch');
                if (this.isValidJ()) {
                    toast().show('Guardando...', 'warning');

                    fetch(settings.restUrl + '/application/' + settings.trip.application.application_id + '/form', {
                        method: 'POST',
                        headers: {
                            'X-WETAG-Token': settings.token,
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        body: new URLSearchParams({
                            member_id: settings.member.member_id,
                            step_form_id: data.step_form_id,
                            name: data.name,
                            form_id: data.form_id
                        }) + '&' + this.serialize()
                    })
                        .then((response) => {
                            return response.json();
                        })
                        .then((response) => {
                            App.load('application', { tripId: db().getCurrentTripId() });
                            page.classList.remove('app-block-touch');
                            toast().show(response.html, response.class);
                        })
                        .catch(error => {
                            isUploading = false;
                            page.classList.remove('app-block-touch');
                        });

                } else {
                    isUploading = false;
                    page.classList.remove('app-block-touch');
                }
            });
    });
});
