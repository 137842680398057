import { settings } from '../libraries/settings';
import { toast } from '../libraries/toast';
import { forms } from '../libraries/forms';
import { hideLoader } from '../libraries/tools';

import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

App.controller('upload-payment', function (page, data) {
    this.restorable = false;

    // Add File
    var images = [];

    function selectFile(image) {
        let ext = image.name.substring(image.name.lastIndexOf('.') + 1).toLowerCase();
        let node = document.createElement('li');
        node.classList.add('animate__animated', 'animate__flipInX');
        node.setAttribute('data-i', 0);

        let reader = new FileReader();
        reader.onload = function (e) {
            let file;

            if (/^(jpg|jpeg|png|gif)$/.test(ext)) {
                file = document.createElement('img');
                file.src = e.target.result;
                file.classList.add('img-fluid');
            } else {
                file = document.createElement('iframe');
                file.src = e.target.result;
                file.type = 'application/pdf';
            }

            node.append(file);

            // Only 1 file allowed
            page.querySelector('.upload-file-trigger').classList.add('d-none');
        };
        reader.readAsDataURL(image);
        images.push(image);


        node.addEventListener('click', function (e) {
            e.stopImmediatePropagation();
            let el = this;
            const i = el.dataset.i;

            App.dialog({
                title: 'Borrar Archivo ?',
                okButton: 'Aceptar',
                cancelButton: 'Cancelar',
            },
                function (confirmed) {
                    if (confirmed) {
                        images.splice(i, 1);
                        el.remove();

                        page.querySelector('.upload-file-trigger').classList.remove('d-none');
                    }
                }
            );
        });

        page.querySelector('.upload-files-list').prepend(node);
    }

    page.addEventListener('appReady', function () {

        page.querySelectorAll('.upload-file-trigger').forEach(el => {
            el.addEventListener('click', function () {
                page.querySelector('.type-' + this.dataset.type).click();
            });
        });

        page.querySelectorAll('.upload-file-input').forEach(el => {
            el.addEventListener('change', function (e) {
                selectFile(this.files[0]);
                this.value = '';
            });
        });

        // Upload Payment
        let isUploading = false;
        page.querySelector('.upload-payment')
            .addEventListener('click', function () {
                if (isUploading) return false;
                isUploading = true;

                let form = page.querySelector('.payment-form');
                let member_id = settings.member.member_id;
                let application_id = settings.trip.application.application_id;

                page.classList.add('app-block-touch');
                if (form.isValidJ()) {

                    if (images.length === 1) {
                        toast().show('<span>Cargando Pago</span><span class="file-1">0%</span>', 'info', true, false, 'uploading d-flex justify-content-between');

                        // Upload Image
                        let formData = new FormData();
                        formData.append('file', images[0]);
                        formData.append('member_id', member_id);
                        formData.append('application_id', application_id);

                        formData.append('date', form.querySelector('#field-date').value);
                        formData.append('method', form.querySelector('#field-method').value);
                        formData.append('amount', form.querySelector('#field-amount').value);


                        var ajax = new XMLHttpRequest();

                        ajax.upload.addEventListener('progress', function (e) {
                            if (e.lengthComputable) {
                                let status = e.loaded / e.total;
                                status = parseInt(status * 100);
                                document.querySelector('.app-toast.uploading span.file-1').innerText = status + '%';
                            }
                        }, false);

                        ajax.addEventListener('load', function (e) {
                            document.querySelector('.app-toast.uploading').remove();

                            try {
                                let response = JSON.parse(this.response);
                                if (response.class == 'success') {
                                    App.load('application', { tripId: application_id });
                                }
                                toast().show(response.html, response.class);
                            } catch (error) {
                                isUploading = false;
                                page.classList.remove('app-block-touch');
                                toast().show('Error: ' + error, 'danger');
                            }
                        }, false);

                        ajax.addEventListener('error', function () {
                            isUploading = false;
                            page.classList.remove('app-block-touch');
                            document.querySelector('.app-toast.uploading span.file-1').innerText = 'Error';
                        }, false);

                        ajax.open('POST', settings.restUrl + '/application/' + application_id + '/payment');
                        ajax.setRequestHeader('X-WETAG-Token', settings.token);
                        ajax.send(formData);

                    } else if (images.length > 1) {
                        isUploading = false;
                        page.classList.remove('app-block-touch');
                        toast().show('Solo se puede adjuntar 1 comprobante.', 'warning');
                    } else {
                        isUploading = false;
                        page.classList.remove('app-block-touch');
                        toast().show('Adjuntá el comprobante para continuar.', 'warning');
                    }

                } else {
                    isUploading = false;
                    page.classList.remove('app-block-touch');
                }
            });

        let formWrap = page.querySelector('.form-payment-wrap');

        formWrap.formJ(forms.payment, { id: 'payment', classes: 'rounded' })
        formWrap.fillJ({ date: moment().format('YYYY-MM-DD') });

        page.querySelector('.payment-form')
            .addEventListener("submit", function (e) {
                e.preventDefault();
                if (!isUploading) page.querySelector('.upload-payment').dispatchEvent(new Event('click'));
            });

        hideLoader(page.querySelector('.app-loader'));
    });
});
