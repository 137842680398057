import { settings } from './settings';
import { templating } from './tools';

import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

var process = function () {
	return {
		filterAction: function (filters) {
			return new Promise((resolve, reject) => {
				let countGroups = 0;
				let countFields = 0;
				let countFilters = 0;
				let passedFilters = 0;

				if (filters) {
					Object.values(filters).forEach(function (fields, i) {
						let data;
						let group = Object.keys(filters)[i];
						if (group === 'application') {
							data = settings.trip.application;
						} else {
							data = settings[group];
						}

						countGroups++;
						countFields += Object.keys(fields).length;

						Object.values(fields).forEach(function (value, i) {
							let field = Object.keys(fields)[i];
							if (field in data) {
								if (
									(Array.isArray(value) && value.includes(data[field])) ||
									data[field] == value
								) {
									passedFilters++;
								}
							}
							countFilters++;
						});

						// Is the last, return promise
						if (countGroups === Object.keys(filters).length && countFields === countFilters) {
							if (countFilters === passedFilters) {
								resolve(true);
							} else {
								resolve(false);
							}
						}

					});

				} else {
					resolve(true);
				}
			});
		},
		formatAction: function (row, item) {
			let uploaded = false;

			// Templating
			['name', 'details', 'instructions', 'url'].forEach(function (name) {
				if (name in row && row[name] && row[name].search('{{') != -1) {
					row[name] = templating(row[name], {
						application: settings.trip.application,
						member: settings.member
					});
				}
			});

			let actionTitleElement = item.querySelector('.action-title');
			let actionDetailsElement = item.querySelector('.action-details');
			let actionIconElement = item.querySelector('.action-icon')
			let actionIconIElement = actionIconElement.querySelector('i');
			let actionCheckboxElement = item.querySelector('.action-checkbox');

			actionTitleElement.innerHTML = row.name;
			actionIconIElement.innerHTML = row.icon;

			if (actionDetailsElement) {
				if (row.details || row.reason) {
					actionDetailsElement.innerHTML = row.details;
				} else {
					actionDetailsElement.remove();
				}
			}

			switch (row.type) {
				case "link":
					// Open Directly
					if (row.example || row.video || row.instructions) {
						item.firstElementChild.setAttribute("data-target", "upload-link");
						item.firstElementChild.setAttribute("data-target-args", JSON.stringify(row));
					} else {
						item.firstElementChild.classList.add('cursor-pointer');
						item.firstElementChild.addEventListener('click', function () {
							window.open(row.url, '_blank', 'location=no');
						});
					}

					if (actionCheckboxElement) {
						actionCheckboxElement.classList.add('action-end-icon', 'fs-5');
						actionCheckboxElement.classList.remove('action-checkbox');
						actionCheckboxElement.innerHTML = '<i class="material-icons">open_in_new</i>';
					}

					break;

				case "field":
					let field_split = row.field.split(".");
					row.field_group = field_split[0];
					row.field_name = field_split[1];
					let field = field_split.reduce(function (prev, current) { return prev[current]; }, {
						member: settings.member,
						application: settings.trip.application
					});

					if (field) {
						actionIconElement.classList.add('border-green');
						actionIconIElement.classList.add('text-green');
						if (actionCheckboxElement) actionCheckboxElement.classList.add('checked');
						uploaded = true;
					} else {
						item.firstElementChild.setAttribute("data-target", "upload-field");
						item.firstElementChild.setAttribute("data-target-args", JSON.stringify(row));
					}

					break;

				case "file":
					item.firstElementChild.classList.add("step-file");

					if (row.status === 0 || row.status === 3) {
						item.firstElementChild.setAttribute("data-target", "upload-file");
						item.firstElementChild.setAttribute("data-target-args", JSON.stringify(row));
					} else if (row.status === 2) {
						item.firstElementChild.setAttribute("data-target", "viewer");
						item.firstElementChild.setAttribute("data-target-args", JSON.stringify({
							urls: row.files,
							type: 'gallery',
							title: row.name
						}));
						if (actionCheckboxElement) actionCheckboxElement.classList.add('checked');
					}

					if (!row.keepIcon) {
						switch (row.status) {
							case 1:
								actionIconElement.classList.add('border-yellow');
								actionIconIElement.classList.add('text-yellow');
								actionIconIElement.innerHTML = 'hourglass_empty';
								break;
							case 2:
								actionIconElement.classList.add('border-green');
								actionIconIElement.classList.add('text-green');
								uploaded = true;
								break;
							case 3:
								actionIconElement.classList.add('border-red');
								actionIconIElement.classList.add('text-red');
								actionDetailsElement.innerHTML += '<div class="text-red">' + row.reason + "</div>";
								break;
						}
					}
					break;

				case "form":
					if (row.status === 0 || row.status === 3 || row.status === 4) {
						item.firstElementChild.setAttribute("data-target", "upload-form");
						item.firstElementChild.setAttribute("data-target-args", JSON.stringify(row));
					}

					if (!row.keepIcon) {
						switch (row.status) {
							case 1:
								actionIconElement.classList.add('border-yellow');
								actionIconIElement.classList.add('text-yellow');
								actionIconIElement.innerHTML = 'hourglass_empty';
								break;
							case 2:
								actionIconElement.classList.add('border-green');
								actionIconIElement.classList.add('text-green');
								if (actionCheckboxElement) actionCheckboxElement.classList.add('checked');
								uploaded = true;
								break;
							case 3:
								actionIconElement.classList.add('border-red');
								actionIconIElement.classList.add('text-red');
								actionDetailsElement.innerHTML = '<div class="text-red">' + row.reason + "</div>";
								break;
						}
					}
					break;

				case "post":
					item.firstElementChild.setAttribute("data-target", "reader");
					item.firstElementChild.setAttribute("data-target-args", JSON.stringify({
						title: row.name,
						content: row.instructions
					}));

					break;
			}

			// Deadline Progress
			let deadline = item.querySelector(".action-deadline");
			if (
				deadline &&
				!uploaded &&
				row.enrollment &&
				(row.deadline_days || row.deadline_date)
			) {
				let start = moment(row.enrollment),
					end, total, left;
				if (row.deadline_days) {
					end = moment(row.enrollment);
					end.add(row.deadline_days, "days");
				} else if (row.deadline_date) {
					end = moment(row.deadline_date);
				}
				total = end.diff(start, "days");
				left = end.diff(moment(), "days");
				if (left < 0) left = 0;
				let plural = left === 1 ? false : true;
				deadline.querySelector(".days").innerHTML = "queda" + (plural ? "n" : "") + " " + left + " " + "día" + (plural ? "s" : "");

				let progress = Math.round(100 - (left / total) * 100);
				let progressColor = progress < 50 ? "#00c566" : "#c50300";
				deadline.style.background = "linear-gradient(90deg, " + progressColor + " " + progress + "%, #ddd 0%)";
			} else {
				if (deadline) deadline.remove();
			}

			return item;
		}
	};
};

export { process }
