import { auth } from '../libraries/auth';
import { hideLoader, tools } from '../libraries/tools';

import gtag from 'ga-gtag';

App.controller('login', function (page) {
	this.restorable = false;
	let form = page.querySelector('.form');
	let fieldPassword = page.querySelector('#field-password');
	let btnSubmit = page.querySelector('.button-submit');
	let btnLogin = page.querySelector('.button-login');

	setTimeout(() => {
		hideLoader(page.querySelector('.app-loader-animated'));
		var pwaInstall = document.getElementsByTagName('pwa-install')[0];
		if (!pwaInstall.isUnderStandaloneMode) pwaInstall.showDialog();
	}, 4800);

	page.addEventListener('appReady', function () {
		gtag('event', 'screen_view', { screen_name: 'Login' });

		page.querySelector('.app-toggle-password')
			.addEventListener('click', function (e) {
				if (this.innerText === 'visibility') {
					this.innerText = 'visibility_off';
					this.previousElementSibling.setAttribute('type', 'text');
				} else {
					this.innerText = 'visibility';
					this.previousElementSibling.setAttribute('type', 'password');
				}
			});

		page.querySelector('.button-recover')
			.addEventListener('click', function () {
				fieldPassword.setAttribute('required', false);
				form.classList.add('recover');
				btnSubmit.innerHTML = 'Recuperar Contraseña';
			});

		btnLogin.addEventListener('click', function () {
			fieldPassword.setAttribute('required', true);
			form.classList.remove('recover');
			btnSubmit.innerHTML = 'Entrar';
		});

		btnSubmit.addEventListener('click', function () {
			form.dispatchEvent(new Event('submit'));
		});

		form.addEventListener('submit', function (e) {
			e.preventDefault();

			if (this.isValidJ()) {
				page.classList.add('app-block-touch');

				if (this.classList.contains('recover')) {
					auth().recoverPassword(
						this.querySelector('[name="username"]').val(),
						function () {
							this.classList.remove('recover');
							page.classList.remove('app-block-touch');
							btnLogin.dispatchEvent('click');
						},
						function () {
							page.classList.remove('app-block-touch');
						}
					);
				} else {
					auth().getToken(
						this.querySelector('[name="username"]').value,
						this.querySelector('[name="password"]').value,
						function () {
							gtag('event', 'login', {
								method: 'WETAG Credentials'
							});
							if (tools().isStandaloneMode())
								gtag('event', 'installed', {
									event_category: 'PWA',
									event_label: 'Installed',
								});

							App.load('application');
						},
						function () {
							page.classList.remove('app-block-touch');
						}
					);
				}
			}
		});

	});
});
