import { settings } from '../libraries/settings';
import { auth } from '../libraries/auth';

import { toast } from '../libraries/toast';
import { uploader } from '../libraries/uploader';

import gtag from 'ga-gtag';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

import PullToRefresh from 'pulltorefreshjs';
import { hideLoader } from '../libraries/tools';

App.controller('tournament', function (page, data) {
    auth().checkToken();

    page.addEventListener('appReady', function () {
        gtag('event', 'screen_view', { screen_name: 'Copa' });

        let p = 1;
        let challengesList = false;
        let application = settings.trip.application;
        const appFileName = '2024-' + application.application_id;

        // Retrieve Challenges
        fetch(settings.restUrl + '/tournament/challenges/' + appFileName, {
            cache: 'no-cache',
            headers: {
                'X-WETAG-Token': settings.token
            }
        })
            .then((response) => {
                return response.json();
            })
            .then((challenges) => {
                challengesList = challenges;
                getChallengePhotos(p)
                    .then((files) => {
                        hideLoader(page.querySelector('.app-loader'));
                    });

                if (challengesList) {
                    let feedOwner = page.querySelector('.feed-owner');
                    for (let c = 0; c < challengesList.length; c++) {

                        // Load Challenges Uploaded
                        if (challengesList[c][3] !== undefined) {
                            let item = document.createElement('div');
                            item.className = 'app-avatar rounded-circle d-flex justify-content-center align-items-center flex-shrink-0';
                            item.setAttribute('data-name', challenges[c][2]);
                            
                            let img = new Image();
                            item.innerHTML = '<i class="material-icons">hourglass_empty</i>';
                            img.onload = function () {
                                item.innerHTML = '';
                                item.style.backgroundImage = `url(${img.src})`;
                            };
                            img.src = settings.contentUrl + '/others/copa/' + challengesList[c][3] + '?_' + new Date().getTime();

                            feedOwner.append(item);
                        }

                        if (moment(challengesList[c][1]).isAfter(moment())) {
                            page.querySelector('.challenge-action').innerHTML = challenges[c][0];

                            let btnUploader = page.querySelector('.challenge-uploader');
                            btnUploader.setAttribute('data-i', c);

                            if (challengesList[c][3] !== undefined) {
                                let challengeTitle = page.querySelector('.challenge-title');
                                challengeTitle.innerHTML = 'Desafío Completo!';
                                challengeTitle.classList.remove('text-white');
                                challengeTitle.classList.add('text-yellow');

                                btnUploader.innerHTML = 'Reemplazar Foto';
                                btnUploader.classList.add('completed');
                            }
                            break;
                        }
                    };
                }
            });

        // Infinite Scrolling
        let appContent = page.querySelector('.app-content');
        let addingPage = false;
        appContent.addEventListener('scroll', function (e) {
            if (appContent.scrollTop + appContent.clientHeight >= appContent.scrollHeight * 0.7 && !addingPage) {
                p++;
                addingPage = true;
                getChallengePhotos(p)
                    .then((files) => {
                        addingPage = files.length > 0 ? false : true;
                    })
            }
        });

        // Uploader
        page.querySelector('.challenge-uploader').addEventListener('click', function (e) {
            e.preventDefault();
            const i = this.dataset.i;
            const timestamp = moment().valueOf();
            const nickname = (application.member_nickname || application.member_name).replace(' ', '-');
            const name = appFileName + '-' + i + '!' + nickname + '!' + timestamp;

            uploader().open({
                limit: 1,
                endpoint: '/upload/tournament',
                rename: name,
                accept: 'image/*,video/*',
                success: function (thumbnails, responses) {
                    page.querySelector('.' + appFileName + '-' + i).remove();
                    try {
                        const response = JSON.parse(responses[0]);
                        addChallengePhoto({
                            file: response.file,
                            name: nickname,
                            date: timestamp,
                            i: i,
                            class: appFileName + '-' + i
                        });
                    } catch (error) {
                        App.load('tournament');
                        toast().show('Error: ' + error, 'danger');

                    }
                }
            });
        });

        function getChallengePhotos(p = 1) {
            return new Promise((resolve, reject) => {
                // Retrieve Fotos Uploaded
                fetch(`${settings.restUrl}/uploads/tournament/2024/${p}?_${new Date().getTime()}`, {
                    cache: 'no-cache',
                    headers: {
                        'X-WETAG-Token': settings.token
                    }
                })
                    .then((response) => {
                        return response.json();
                    })
                    .then((response) => {
                        let f = 0;
                        if (response.files) {
                            let files = Object.values(response.files);
                            files.forEach((file) => {
                                const fileName = file.split('.')[0];
                                const parts = fileName.split('!')
                                const c = parts[0].split('-')[2];

                                addChallengePhoto({
                                    file: file,
                                    name: parts[1],
                                    date: parts[2],
                                    i: c,
                                    class: parts[0]
                                });
                                f++;
                            });
                            if (f === files.length) resolve(files);
                        } else {
                            resolve([]);
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        reject(false);
                    });
            });
        }

        function addChallengePhoto(item) {
            let post = document.createElement('div');
            post.className = 'post d-flex flex-column ' + item.class;
            let img = new Image();
            let label = '<b>' + item.name.replace('-', ' ') + '</b><br>para el desafío <b>' + challengesList[parseInt(item.i)][2] + '</b>';

            img.onload = function () {
            };
            img.src = settings.contentUrl + '/others/copa/' + item.file + '?_' + new Date().getTime();
            img.classList = 'img-fluid rounded';
            post.append(img);
            post.innerHTML += ('<div class="post-label p-2 p-md-3 rounded-bottom align-items-center"><span>' + label + '</span> <i>' + moment(item.date, 'x').fromNow() + '</i></div>');


            page.querySelector('.feed').append(post);
        }

        PullToRefresh.init({
            mainElement: '.app-content',
            triggerElement: '.page-tournament',
            instructionsPullToRefresh: 'Arrastrá hacia abajo para actualizar',
            instructionsReleaseToRefresh: 'Soltá para actualizar',
            instructionsRefreshing: 'Actualizando',
            onRefresh() {
                App.load('tournament');
            },
            shouldPullToRefresh: function () {
                return !this.mainElement.scrollTop;
            }
        });
        
    });

});