import { settings } from './settings';
import { templating } from '../libraries/tools';
import { toast } from './toast';

var dialog;
let files = [];
let thumbnails = [];
let responses = [];

var uploader = function () {
    return {
        open: function (options = { endpoint: false, rename: false, limit: false, success: false, accept: 'image/*,.pdf' }) {
            if (!options.endpoint) {
                toast().show('Falta setear el endpoint del uploader', 'danger');
                return false;
            }

            fetch('uploader.html')
                .then((response) => {
                    return response.text();
                })
                .then((response) => {
                    const parser = new DOMParser();
                    const html = parser.parseFromString(templating(response, {
                        accept: { types: options.accept }
                    }), 'text/html');

                    dialog = html.querySelector('.app-uploader');
                    dialog.querySelectorAll('.upload-file-trigger')
                        .forEach((el) => {
                            el.addEventListener('click', function (e) {
                                dialog.querySelector('.type-' + this.dataset.type).click();
                            });
                        })

                    dialog.querySelectorAll('.upload-file-input')
                        .forEach((el) => {
                            el.addEventListener('change', function () {
                                if (options.limit === false || files.length < options.limit) {
                                    uploader().pickFile(this.files[0]);
                                    this.value = '';
                                } else {
                                    toast().show('Se pueden cargar un total de ' + options.limit + ' archivo' + (options.limit > 1 ? 's' : ''), 'warning');
                                }
                            });
                        })

                    dialog.querySelector('.upload-files').setAttribute('data-options', JSON.stringify(options));
                    dialog.querySelector('.upload-files').addEventListener('click', (e) => {
                        const options = JSON.parse(e.target.dataset.options);
                        uploader().upload(options)
                            .then(function () {
                                if (options.success) {
                                    options.success(thumbnails, responses);
                                }
                            })
                            .then(function () {
                                uploader().close();
                            })
                    });

                    dialog.querySelector('.upload-close')
                        .addEventListener('click', () => { uploader().close() });

                    document.querySelector('.app-page').append(dialog);
                });
        },
        pickFile: function (file) {
            let c = dialog.querySelectorAll('.upload-files-list > li').length;
            let node = document.createElement('li');
            node.className = 'animate__animated animate__flipInX';
            node.setAttribute('data-i', c);

            let reader = new FileReader();
            reader.onload = function (e) {
                let thumbnail = document.createElement('img');
                thumbnail.src = e.target.result;
                thumbnail.classList.add('img-fluid');

                node.append(thumbnail);
                thumbnails.push(e.target.result);
            };
            reader.readAsDataURL(file);
            files.push(file);

            node.addEventListener('click', function (e) {
                e.stopImmediatePropagation();
                let el = this;
                const i = el.dataset.i;

                App.dialog({
                    title: 'Borrar Archivo ?',
                    okButton: 'Aceptar',
                    cancelButton: 'Cancelar',
                },
                    function (confirmed) {
                        if (confirmed) {
                            files.splice(i, 1);
                            thumbnails.splice(i, 1);
                            el.remove();
                        }
                    }
                );
            });

            dialog.querySelector('.upload-files-list').prepend(node);
        },
        upload: function (options = { endpoint: false, rename: false }) {
            return new Promise((resolve, reject) => {
                const page = document.querySelector('.app-page');

                if (options.endpoint && files.length > 0) {
                    page.classList.add('app-block-touch');
                    toast().show('<b>Cargando Archivos</b>', 'info', true, false, 'uploading');

                    files.forEach(function (file, i) {
                        const c = i + 1;
                        document.querySelector('.app-toast.uploading').innerHTML += '<br>Archivo ' + c + ' <span class="file-' + c + '">0%</span>';

                        let formData = new FormData();
                        formData.append('file', file);
                        formData.append('rename', options.rename);

                        let ajax = new XMLHttpRequest();
                        ajax.upload.addEventListener('progress', function (e) {
                            if (e.lengthComputable) {
                                let status = e.loaded / e.total;
                                status = parseInt(status * 100);
                                document.querySelector('.app-toast.uploading span.file-' + c).innerText = status + '%';
                            }
                        }, false);

                        ajax.addEventListener('load', function (e) {
                            responses.push(e.target.response);

                            if (c === files.length) {
                                page.classList.remove('app-block-touch');
                                let toastUploading = document.querySelector('.app-toast.uploading');
                                toastUploading.classList.add('animate__flipOutX');
                                toastUploading.addEventListener('animationend', (e) => {
                                    e.target.remove();
                                });

                                resolve(thumbnails, responses);
                            }
                        }, false);

                        ajax.addEventListener('error', function () {
                            document.querySelector('.app-toast.uploading span.file-' + c).innerText = 'Error';
                        }, false);

                        ajax.open('POST', settings.restUrl + options.endpoint);
                        ajax.setRequestHeader('X-WETAG-Token', settings.token);
                        ajax.send(formData);

                    });
                } else {
                    reject(false);
                }
            });

        },
        close: function () {
            dialog.remove();
            files = [];
            thumbnails = [];
            responses = [];
            dialog = false;
        }
    };
};

export { uploader }