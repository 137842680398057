import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

Element.prototype.serialize = function () {
    const formData = new FormData(this);
    const queryString = [];

    formData.forEach((value, key) => {
        queryString.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
    });

    return queryString.join('&');
};

HTMLElement.prototype.formJ = function (data = false, options) {
    const settings = {
        id: "form",
        classes: false,
        ...options
    };

    const target = this;
    const container = document.createElement('div');
    container.classList.add('app-fields');

    const form = document.createElement('form');
    form.classList.add(settings.id + "-form");
    form.setAttribute('autocomplete', 'off');
    if (settings.classes) {
        settings.classes.split(' ').forEach((className) => {
            container.classList.add(className);
        });
    }

    if (data) {
        format(data);
    }

    function format(fields) {
        if (fields) {
            Object.keys(fields).forEach((id) => {
                let field = fields[id];
                if (Number.isInteger(id)) id = field.name; // Fix for Platform

                field.required = field.required || false;
                field.col = field.col || 6;
                field.colMD = field.colMD || field.col;
                field.label = field.label || "";
                field.value = field.value || false;
                field.readonly = field.readonly || false;
                field.classes = field.classes || false;
                field.placeholder = field.placeholder || '';
                field.description = field.description || '';

                const formItem = document.createElement('div');
                formItem.classList.add('app-field', 'field-type-' + field.type);

                let formInput = document.createElement('input');
                formInput.classList.add('app-input');

                if (
                    field.type !== "hidden" &&
                    field.type !== "spacer" &&
                    field.type !== "div" &&
                    field.type !== "button" &&
                    field.type !== "submit" &&
                    field.type !== "title" &&
                    field.type !== 'checkboxes'
                ) {
                    const label = document.createElement('label');
                    label.setAttribute('for', 'field-' + id);
                    label.textContent = field.label;
                    formItem.appendChild(label);
                }

                switch (field.type) {
                    case "date":
                    case "time":
                        formInput.type = field.type;
                        formInput.size = 10;
                        break;
                    case "datetime":
                        formInput.type = "datetime-local";
                        formInput.size = 10;
                        break;
                    case "textarea":
                        formInput = document.createElement('textarea');
                        formInput.classList.add('app-input');
                        formInput.rows = 7;
                        formInput.placeholder = field.description;
                        break;
                    case "checkbox":
                        formInput.type = "checkbox";
                        formInput.value = field.value || 1;
                        break;
                    case "number":
                        formInput.type = 'number';
                        formInput.setAttribute('pattern', '[0-9]*');
                        formInput.setAttribute('inputmode', 'numeric');
                        formInput.placeholder = field.description;
                        break;
                    case "rating-emotions":
                        formInput = document.createElement('span');
                        formInput.classList.add('gl-star-rating');
                        formInput.innerHTML = '<select class="rating-emotions" name="' + id + '" id="element-' + id + '">' +
                            '<option value=""></option>' +
                            '<option value="5">5 Estrellas</option>' +
                            '<option value="4">4 Estrellas</option>' +
                            '<option value="3">3 Estrellas</option>' +
                            '<option value="2">2 Estrellas</option>' +
                            '<option value="1">1 Estrellas</option>' +
                            '</select>' +
                            '<span class="gl-star-rating--stars rounded">' +
                            '<span data-value="1" class=""><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="gl-emote" style="pointer-events: none;"><circle class="gl-emote-bg" fill="#FFA98D" cx="12" cy="12" r="10"></circle><path fill="#393939" d="M12 14.6c1.48 0 2.9.38 4.15 1.1a.8.8 0 01-.79 1.39 6.76 6.76 0 00-6.72 0 .8.8 0 11-.8-1.4A8.36 8.36 0 0112 14.6zm4.6-6.25a1.62 1.62 0 01.58 1.51 1.6 1.6 0 11-2.92-1.13c.2-.04.25-.05.45-.08.21-.04.76-.11 1.12-.18.37-.07.46-.08.77-.12zm-9.2 0c.31.04.4.05.77.12.36.07.9.14 1.12.18.2.03.24.04.45.08a1.6 1.6 0 11-2.34-.38z"></path></svg></span>' +
                            '<span data-value="2" class=""><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="gl-emote" style="pointer-events: none;"><circle class="gl-emote-bg" fill="#FFC385" cx="12" cy="12" r="10"></circle><path fill="#393939" d="M12 14.8c1.48 0 3.08.28 3.97.75a.8.8 0 11-.74 1.41A8.28 8.28 0 0012 16.4a9.7 9.7 0 00-3.33.61.8.8 0 11-.54-1.5c1.35-.48 2.56-.71 3.87-.71zM15.7 8c.25.31.28.34.51.64.24.3.25.3.43.52.18.23.27.33.56.7A1.6 1.6 0 1115.7 8zM8.32 8a1.6 1.6 0 011.21 2.73 1.6 1.6 0 01-2.7-.87c.28-.37.37-.47.55-.7.18-.22.2-.23.43-.52.23-.3.26-.33.51-.64z"></path></svg></span>' +
                            '<span data-value="3" class=""><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="gl-emote" style="pointer-events: none;"><circle class="gl-emote-bg" fill="#FFD885" cx="12" cy="12" r="10"></circle><path fill="#393939" d="M15.33 15.2a.8.8 0 01.7.66.85.85 0 01-.68.94h-6.2c-.24 0-.67-.26-.76-.7-.1-.38.17-.81.6-.9zm.35-7.2a1.6 1.6 0 011.5 1.86A1.6 1.6 0 1115.68 8zM8.32 8a1.6 1.6 0 011.21 2.73A1.6 1.6 0 118.33 8z"></path></svg></span>' +
                            '<span data-value="4" class=""><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="gl-emote" style="pointer-events: none;"><circle class="gl-emote-bg" fill="#FFD885" cx="12" cy="12" r="10"></circle><path fill="#393939" d="M15.45 15.06a.8.8 0 11.8 1.38 8.36 8.36 0 01-8.5 0 .8.8 0 11.8-1.38 6.76 6.76 0 006.9 0zM15.68 8a1.6 1.6 0 011.5 1.86A1.6 1.6 0 1115.68 8zM8.32 8a1.6 1.6 0 011.21 2.73A1.6 1.6 0 118.33 8z"></path></svg></span>' +
                            '<span data-value="5" class=""><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="gl-emote" style="pointer-events: none;"><circle class="gl-emote-bg" fill="#FFD885" cx="12" cy="12" r="10"></circle><path fill="#393939" d="M16.8 14.4c.32 0 .59.2.72.45.12.25.11.56-.08.82a6.78 6.78 0 01-10.88 0 .78.78 0 01-.05-.87c.14-.23.37-.4.7-.4zM15.67 8a1.6 1.6 0 011.5 1.86A1.6 1.6 0 1115.68 8zM8.32 8a1.6 1.6 0 011.21 2.73A1.6 1.6 0 118.33 8z"></path></svg></span>' +
                            '</span>'
                        break;
                    case 'checkboxes':
                        formInput = document.createElement('div');
                        formInput.classList.add('app-field', 'app-type-checkboxes', id, 'size-' + field.col, 'size-md-' + field.colMD);
                        const labelCheckboxes = document.createElement('label');
                        labelCheckboxes.textContent = field.label;
                        formInput.appendChild(labelCheckboxes);
                        if (field.description) {
                            const description = document.createElement('div');
                            description.classList.add('text-secondary', 'mt-1');
                            description.innerHTML = field.description;
                            formInput.appendChild(description);
                        }

                        if ('options' in field && Object.keys(field.options).length > 0) {
                            Object.keys(field.options).forEach((order) => {
                                const option = field.options[order];
                                const checkWrapper = document.createElement('div');
                                checkWrapper.classList.add('app-field', 'field-type-checkbox');

                                const checkboxLabel = document.createElement('label');
                                checkboxLabel.setAttribute('for', 'field-' + id + '-' + order);
                                checkboxLabel.textContent = option.label;
                                checkWrapper.appendChild(checkboxLabel);

                                const checkboxInput = document.createElement('input');
                                checkboxInput.type = 'checkbox';
                                checkboxInput.value = option.value;
                                checkboxInput.name = id + '[]';
                                checkboxInput.id = 'field-' + id + '-' + order;
                                checkboxInput.dataset.min = field.min_length || 0;
                                checkboxInput.dataset.max = field.max_length || 0;

                                checkWrapper.appendChild(checkboxInput);
                                formInput.appendChild(checkWrapper);
                            });
                        }
                        break;
                    case 'rating':
                    case 'select':
                        formInput = document.createElement('select');
                        formInput.classList.add('app-input');
                        if ('options' in field && Object.keys(field.options).length > 0) {
                            Object.keys(field.options).forEach((order) => {
                                const option = field.options[order];
                                const optionElement = document.createElement('option');
                                optionElement.value = option.value;
                                optionElement.textContent = option.label;
                                if (option.selected) optionElement.selected = true;
                                if (option.data) optionElement.dataset.data = option.data;
                                formInput.appendChild(optionElement);
                            });
                        }

                        if (field.type === 'rating') {
                            formInput.classList.add('rating-stars');
                        }
                        break;
                    case 'button':
                        formInput = document.createElement('div');
                        formInput.textContent = field.label;
                        formInput.classList.add('app-button');
                        formInput.setAttribute('data-clickable-class', 'active');
                        break;
                    case 'submit':
                        formInput.type = 'submit';
                        break;
                    case 'div':
                        formInput = document.createElement('div');
                        formInput.textContent = field.label;
                        if (field.description) {
                            const description = document.createElement('div');
                            description.innerHTML = field.description.replace(/(?:\r\n|\r|\n)/g, '<br>');
                            formInput.appendChild(description);
                        }
                        break;
                    case 'title':
                        formInput = document.createElement('div');
                        formInput.classList.add('p-3', 'pb-2');
                        formInput.innerHTML = '<h4 class="m-0">' + field.label + '</h4>';
                        if (field.description) {
                            const description = document.createElement('div');
                            description.innerHTML = field.description.replace(/(?:\r\n|\r|\n)/g, '<br>');
                            formInput.appendChild(description);
                        }
                        break;
                    case 'spacer':
                        formInput = document.createElement('div');
                        break;
                    default:
                        formInput.type = field.type;
                        formInput.placeholder = field.description;
                }

                formItem.classList.add('field-' + id, 'size-' + field.col, 'size-md-' + field.colMD);
                formInput.name = id;
                formInput.id = 'field-' + id;

                if (field.required) {
                    formItem.classList.add('required');
                    formInput.required = true;
                }

                if (field.value) formInput.value = field.value;
                if (field.readonly) formInput.readOnly = true;
                if (field.classes){
                    field.classes.split(' ').forEach((className) => {
                        formInput.classList.add(className);
                    });
                }
                if (field.min_length) formInput.min = field.min_length;
                if (field.max_length) formInput.max = field.max_length;

                if (field.data) {
                    if (Array.isArray(field.data)) {
                        field.data.forEach(attr => formInput.setAttribute('data-' + attr, true));
                    } else if (typeof field.data === "object") {
                        Object.keys(field.data).forEach(attr => formInput.setAttribute('data-' + attr, field.data[attr]));
                    }
                }

                if (field.type === 'submit' || field.type === 'hidden' || field.type === 'checkboxes') {
                    container.appendChild(formInput);
                } else {
                    formItem.appendChild(formInput);
                    container.appendChild(formItem);
                }
            });
        }

        form.appendChild(container);
        target.innerHTML = '';
        target.appendChild(form);
    }

    return target;
};

Element.prototype.fillJ = function (data = false) {
    const form = this;
    if (data) {
        fill(form, data);
    }

    function fill(form, data) {
        form.data = { data };
        if (Object.keys(data).length > 0) {
            Object.keys(data).forEach(field => {
                let value = data[field];
                let input;
                for (let c = 0; c < 3; c++) {
                    switch (c) {
                        case 0:
                            input = form.querySelector('[name="' + field + '"]');
                            break;
                        case 1:
                            input = form.querySelector('[name="' + field + '[]"]');
                            if (input && input.tagName === 'SELECT') {
                                input.querySelectorAll('option').forEach(option => option.selected = false); // Clean Multiple Select
                            }
                            break;
                        case 2:
                            input = form.querySelector('[name="' + field + '[1]"]');
                            break;
                    }
                    if (input) {
                        break;
                    }
                }

                if (input && value) {
                    if (Array.isArray(value) || typeof value === 'object') {
                        if (input.tagName === 'SELECT') {
                            input.querySelectorAll('option').forEach(option => option.selected = false); // Clean Multiple Select
                            value.forEach(val => {
                                const option = input.querySelector('[value="' + val + '"]');
                                if (option) option.selected = true;
                            });
                        } else if (input.type) {
                            value.forEach((val, i) => {
                                switch (input.type) {
                                    case 'datetime-local':
                                        form.querySelector('[name="' + field + "[" + i + ']"]').value = new Date(val).toISOString().slice(0, 19);
                                        break;
                                    case 'date':
                                        form.querySelector('[name="' + field + "[" + i + ']"]').value = new Date(val).toISOString().slice(0, 10);
                                        break;
                                    case 'checkbox':
                                        form.querySelector('[name="' + field + '[]"][value="' + val + '"]').checked = true;
                                        break;
                                }
                            });
                        }
                    } else if (input.type === 'radio' || input.type === 'checkbox') {
                        form.querySelector('[name="' + field + '"][value="' + value + '"]').checked = true;
                    } else if (input.tagName === 'SELECT') {
                        const option = input.querySelector('option[value="' + value + '"]');
                        if (option) option.selected = true;
                    } else {
                        switch (input.type) {
                            case 'datetime-local':
                                value = new Date(value).toISOString().slice(0, 19);
                                break;
                            case 'date':
                                value = new Date(value).toISOString().slice(0, 10);
                                break;
                        }
                        input.value = value;
                    }
                }
            });
        }
    }
    return this;
};

HTMLElement.prototype.isValidJ = function () {
    var form = this;
    var valid = true;
    var fields = form.querySelectorAll(':required');  // Encuentra todos los campos requeridos

    form.querySelectorAll('.error').forEach(function (el) {
        el.classList.remove('error');
    });

    fields.forEach(function (input) {
        switch (input.type) {
            case 'select-one':
                if (
                    input.selectedIndex === -1 || // No hay opción seleccionada
                    input.options[input.selectedIndex].value === ''
                ) {
                    input.parentElement.classList.add('error');
                    valid = false;
                }
                break;

            case 'checkbox':
                let siblingsChecked = document.querySelectorAll('[name="' + input.name + '"]:checked').length;
                const min = input.dataset.min;
                const max = input.dataset.max;

                if (
                    (!input.checked && siblingsChecked === 0) || // Ningún checkbox seleccionado y es obligatorio
                    (siblingsChecked > 0 && (
                        (min > 0 && siblingsChecked < min) || // Menor que el mínimo
                        (max > 0 && siblingsChecked > max)    // Más que el máximo
                    ))
                ) {
                    input.parentElement.classList.add('error');
                    valid = false;
                }
                break;

            default:
                if (!input.value.trim()) {  // Verifica si el campo tiene valor
                    input.parentElement.classList.add('error');
                    valid = false;
                }
        }
    });

    return valid;
};

export { isValidJ }