import { settings } from '../libraries/settings';
import { toast } from '../libraries/toast';
import { db } from '../libraries/db';
import { hideLoader } from '../libraries/tools';

App.controller('upload-field', function (page, data) {
    this.restorable = false;

    let fields = {};

    fields[data.field_name] = {
        label: data.name,
        type: data.field_type,
        required: true,
        col: 12,
    };

    page.querySelector('.form-wrap').formJ(fields, { classes: 'rounded pt-1 bg-white' });

    page.querySelector(".upload-title").innerHTML = data.name;
    let uploadDetails = page.querySelector('.upload-details');
    if (data.instructions) {
        uploadDetails.innerHTML = data.instructions;
    } else {
        uploadDetails.remove();
    }

    page.addEventListener('appReady', function () {
        hideLoader(page.querySelector('.app-loader'));

        let isUploading = false;
        page.querySelector(".upload-step-field")
            .addEventListener("click", function () {
                if (!isUploading) page.querySelector('.form-form').dispatchEvent(new Event('submit'));
            });

        page.querySelector('.form-form')
            .addEventListener("submit", function (e) {
                if (isUploading) return false;
                isUploading = true;

                e.preventDefault();
                e.stopPropagation();

                page.classList.add('app-block-touch');

                if (this.isValidJ()) {
                    toast().show('Guardando...', 'warning');

                    let field_group_id;
                    if (data.field_group == "application") {
                        field_group_id = settings.trip.application.application_id;
                    } else if (data.field_group == "member") {
                        field_group_id = settings.member.member_id;
                    }

                    fetch(settings.restUrl + '/' + data.field_group + '/' + field_group_id, {
                        method: 'POST',
                        headers: {
                            'X-WETAG-Token': settings.token,
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        body: this.serialize()
                    })
                        .then((response) => {
                            return response.json();
                        })
                        .then((response) => {
                            db().getMember(settings.member.member_id);
                            App.load('application', { tripId: db().getCurrentTripId() });

                            page.classList.remove('app-block-touch');
                            toast().show(response.html, response.class);
                        })
                        .catch(error => {
                            isUploading = false;
                            page.classList.remove('app-block-touch');
                        });
                } else {
                    isUploading = false;
                    page.classList.remove('app-block-touch');
                }

            });
    });
});
