import { settings } from '../libraries/settings';

import 'flickity/dist/flickity.min.css';
import Flickity from 'flickity';

App.controller('viewer', function (page, data) {
	this.restorable = false;

	page.addEventListener('appReady', function () {
		let viewer = page.querySelector('.viewer-file');

		if ('type' in data) {
			if (data.type === 'gallery') {
				if (data.urls.length) {
					data.urls.forEach(function (file, i) {
						viewer.innerHTML += '<img src="' + file + '" >';

						if (i + 1 === data.urls.length) {
							var flkty = new Flickity(viewer, {
								prevNextButtons: true,
								pageDots: false,
								imagesLoaded: true,
								setGallerySize: false
							});
						}
					});
				}

			} else if (data.type === 'file') {
				let file;
				let ext = data.file.substring(data.file.lastIndexOf('.') + 1).toLowerCase();
				let version = "?v=" + Math.floor(Math.random() * 100 + 1);
				let url = settings.contentUrl + '/' + data.folder + '/' + data.file;

				if (/^(pdf|tiff|ppt|pps|doc|docx|gsheet|gdoc|gslides|txt)$/.test(ext)) {
					file = `<iframe src="https://docs.google.com/gview?embedded=true&url=${url}" type="application/pdf" class="border-0 w-100 h-100"></iframe>`;
				} else if (/^(jpg|jpeg|png|gif)$/.test(ext)) {
					file = `<img src="${url}${version}" class="img-fluid bg-white">`;
				}
				viewer.innerHTML = file;
			}
		}
	});
});
