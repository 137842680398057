import gtag from 'ga-gtag';
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

import { hideLoader } from '../libraries/tools';

App.controller('reader', function (page, data) {
    this.restorable = false;

    if (!('acf' in data)) data.acf = { type: 'standard' }

    page.addEventListener('appReady', function () {
        gtag('event', 'page_view', { page_title: data.title });

        let postTitle = page.querySelector('.post-title');
        let postContent = page.querySelector('.post-content');
        let sectionHero = page.querySelector('.section-hero');
        let sectionPlayer = page.querySelector('.section-player');

        postTitle.innerHTML = data.title;
        postContent.innerHTML = data.content;

        switch (data.acf.type) {
            case 'youtube':
                let postPlayer = page.querySelector('#reader-player');
                postPlayer.setAttribute('data-plyr-embed-id', data.acf.youtube_id)
                sectionPlayer.classList.remove('d-none');

                const player = new Plyr(postPlayer, {
                    autoplay: true,
                    muted: true,
                    controls: ['play-large', 'play', 'fullscreen', 'progress', 'pip', 'airplay', 'mute', 'volume'],
                    youtube: { noCookie: true }
                });

                player.on('ready', (event) => {
                    const instance = event.detail.plyr;
                    console.log(instance);
                });

                page.classList.add('dark-mode');
                sectionHero.remove();

                if (!data.content) {
                    sectionPlayer.classList.add('h-100', 'd-flex', 'align-items-center');
                    postContent.parentNode.remove();
                }
                break;

            default:
                if (data.cover) {
                    sectionHero.style.backgroundImage = `url(${data.cover})`;
                    postTitle.classList.add('text-white', 'h1', 'text-center');
                } else {
                    sectionHero.classList.remove('section-hero');
                    sectionHero.classList.add('app-desktop-wrapper');
                    postTitle.classList.add('mt-4', 'h1', 'mb-n3', 'h2');
                }

                break;
        }

        hideLoader(page.querySelector('.app-loader'));

        // Follow Links
        let followLinks = page.querySelectorAll('.follow-link');
        if (followLinks) {
            followLinks.forEach((link) => {
                link.addEventListener('click', function (e) {
                    e.preventDefault();
                    window.open(this.href, '_blank', 'location=no');
                });
            })
        }
        // Collapse Buttons
        let appCollapseBtns = postContent.querySelectorAll('.app-collapse .app-button');
        if (appCollapseBtns) {
            appCollapseBtns.forEach((el) => {
                el.addEventListener('click', (e) => {
                    e.target.parentNode.classList.toggle('open');
                });
            });
        }
    });
});
