//import { J } from './libraries/J.js';
import { auth } from './libraries/auth';
import { db } from './libraries/db';

import { install } from 'ga-gtag';

import './controllers/service.worker.js';

import './controllers/misc.js';
import './controllers/main.js';

import './controllers/application.js';
import './controllers/profile.js';
import './controllers/content.js';

import './controllers/upload.file.js';
import './controllers/upload.form.js';
import './controllers/upload.field.js';
import './controllers/upload.link.js';
import './controllers/upload.payment.js';

import './controllers/reader.js';
import './controllers/viewer.js';

import './controllers/tournament.js';

import './controllers/login.js';

import 'material-icons/iconfont/filled.css';

import '@khmyznikov/pwa-install';

App.setDefaultTransition('explode-in');

window.onload = (event) => {
  
    auth().init();
    db().init();

    let params = (new URL(document.location)).searchParams;
    let page = params.get('page');
    let pages = ['application', 'profile', 'content', 'profile'];

    if (page && pages.includes(page)) {
        App.load(page);
    } else {
        auth().checkToken()
            .then(function (token) {
                if (token) {
                    try {
                        App.restore({
                            maxAge: 5 * 60 * 1000
                        });
                    } catch (error) {
                        App.load(pages[0]);
                    }

                }
            })
    }
}