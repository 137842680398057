import { settings, videoTutorialOptions } from '../libraries/settings';
import { hideLoader } from '../libraries/tools';

import YouTubePlayer from 'youtube-player';

App.controller('upload-link', function (page, data) {
    this.restorable = false;

    // Title & Details
    page.querySelector(".upload-title").innerHTML = data.name;
    if (data.instructions) {
        page.querySelector(".upload-details").innerHTML = data.instructions;
    } else {
        page.querySelector(".upload-details").remove();
    }

    page.addEventListener('appReady', function () {

        // Photo Example
        if (data.example) {
            page.querySelector(".upload-example")
                .innerHTML = '<img src="' + settings.contentUrl + '/others/actions/' + data.example + '" class="img-fluid rounded">';
        } else {
            page.querySelector(".upload-example").remove();
        }

        // Video Tutorial
        if (data.video) {
            let player = YouTubePlayer("upload-video", Object.assign({
                videoId: data.video,
                width: page.querySelector("#upload-video").offsetWidth
            }, videoTutorialOptions));

            page.querySelector(".upload-video").style.visibility = 'visible';
        } else {
            page.querySelector(".upload-video").remove();
        }

        hideLoader(page.querySelector('.app-loader'));

        page.querySelector('.upload-follow-link')
            .addEventListener('click', function (e) {
                e.preventDefault();
                window.open(data.url, '_blank', 'location=no');
            });
    });
});
