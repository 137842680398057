import { settings, videoTutorialOptions } from '../libraries/settings';
import { toast } from '../libraries/toast';
import { db } from '../libraries/db';

import YouTubePlayer from 'youtube-player';
import { hideLoader } from '../libraries/tools';

App.controller('upload-file', function (page, data) {
    this.restorable = false;

    // Add File
    var images = [];

    function selectFile(image) {
        let c = page.querySelectorAll('.upload-files-list > li').length;
        let ext = image.name.substring(image.name.lastIndexOf('.') + 1).toLowerCase();
        let node = document.createElement('li');
        node.className = 'animate__animated animate__flipInX';
        node.setAttribute('data-i', c);

        let reader = new FileReader();
        reader.onload = function (e) {
            let file;
            if (/^(jpg|jpeg|png|gif)$/.test(ext)) {
                file = document.createElement('img');
                file.src = e.target.result;
                file.classList.add('img-fluid');
            } else {
                file = document.createElement('iframe');
                file.src = e.target.result;
                file.type = 'application/pdf';
            }
            node.append(file);
        };
        reader.readAsDataURL(image);
        images.push(image);

        node.addEventListener('click', function (e) {
            e.stopImmediatePropagation();
            let el = this;
            const i = el.dataset.i;

            App.dialog({
                title: 'Borrar Archivo ?',
                okButton: 'Aceptar',
                cancelButton: 'Cancelar',
            },
                function (confirmed) {
                    if (confirmed) {
                        images.splice(i, 1);
                        el.remove();
                    }
                }
            );
        });

        page.querySelector('.upload-files-list').prepend(node);
    }


    page.addEventListener('appReady', function () {

        // Title & Details
        page.querySelector(".upload-title").innerHTML = data.name;

        if (data.instructions) {
            page.querySelector(".upload-details").innerHTML = data.instructions;
        } else {
            page.querySelector(".upload-details").remove();
        }

        // Template
        if (data.template) {
            let btnSeeDoc = page.querySelector('.button-seedoc');
            btnSeeDoc.setAttribute('data-target-args', JSON.stringify({
                file: data.template,
                folder: 'others/actions',
                type: 'file',
            }));

            page.querySelector('.button-emaildoc')
                .addEventListener('click', function () {
                    page.classList.add('app-block-touch');
                    toast().show('Enviando...', 'warning');

                    fetch(settings.restUrl + '/uploads/emaildoc', {
                        method: 'POST',
                        headers: {
                            'X-WETAG-Token': settings.token,
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        body: new URLSearchParams({
                            member_id: settings.member.member_id,
                            step_file_id: data.step_file_id
                        })
                    })
                        .then((response) => {
                            return response.json();
                        })
                        .then((response) => {
                            toast().show(response.html, response.class);
                            page.classList.remove('app-block-touch');
                        })
                        .catch(error => {
                            page.classList.remove('app-block-touch');
                        });

                });
        } else {
            page.querySelector('.upload-template').remove();
        }

        // Video Tutorial
        if (data.video) {
            let player = YouTubePlayer("upload-video", Object.assign({
                videoId: data.video,
                width: page.querySelector("#upload-video").offsetWidth
            }, videoTutorialOptions));

            page.querySelector(".upload-video").style.visibility = 'visible';
        } else {
            page.querySelector(".upload-video").remove();
        }

        hideLoader(page.querySelector('.app-loader'));

        // Delete Previous Files Uploaded
        if (navigator.onLine) {
            fetch(settings.restUrl + '/uploads/empty', {
                method: 'POST',
                headers: {
                    'X-WETAG-Token': settings.token,
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({
                    path: settings.member.member_id + '/' + settings.trip.application.application_id + '/' + data.step_file_id
                })
            });
        }

        if (data.requires_upload === 1) {
            page.querySelectorAll('.upload-file-trigger').forEach(el => {
                el.addEventListener('click', function () {
                    page.querySelector('.type-' + this.dataset.type).click();
                });
            });

            page.querySelectorAll('.upload-file-input').forEach(el => {
                el.addEventListener('change', function (e) {
                    selectFile(this.files[0]);
                    this.value = '';
                });
            });

            // Upload File
            let isUploading = false;
            page.querySelector('.upload-step-file')
                .addEventListener("click", function () {
                    if (isUploading) return false;
                    isUploading = true;
                    page.classList.add('app-block-touch');

                    if (images.length > 0) {
                        toast().show("<b>Cargando Archivos</b>", "info", true, false, "uploading");

                        // Upload Images
                        let filesProcessed = 0;
                        let filesUploaded = 0;
                        let filesErrors = [];
                        let filesUploading = new Promise((resolve, reject) => {
                            images.forEach(function (file, i) {
                                const c = i + 1;
                                document.querySelector('.app-toast.uploading').innerHTML += '<br>Archivo ' + c + ' <span class="file-' + c + '">0%</span>';

                                let formData = new FormData();
                                formData.append('file', file);
                                formData.append('folder', data.step_file_id);
                                formData.append('member_id', settings.member.member_id);
                                formData.append('application_id', settings.trip.application.application_id);

                                var ajax = new XMLHttpRequest();
                                ajax.upload.addEventListener('progress', function (e) {
                                    if (e.lengthComputable) {
                                        let status = e.loaded / e.total;
                                        status = parseInt(status * 100);
                                        document.querySelector('.app-toast.uploading span.file-' + c).innerText = status + '%';
                                    }
                                }, false);

                                ajax.addEventListener('load', function (e) {
                                    filesProcessed++;
                                    const response = JSON.parse(e.currentTarget.response);

                                    if (response.class === 'success') {
                                        filesUploaded++;
                                    } else {
                                        document.querySelector('.app-toast.uploading span.file-' + c).innerText = 'Error';
                                        filesErrors.push('Archivo ' + c + ': ' + response.html);
                                    }

                                    if (filesProcessed === images.length) {
                                        if (filesUploaded === filesProcessed) {
                                            resolve(true);
                                        } else {
                                            reject({
                                                class: 'danger',
                                                html: 'Error al cargar algunos de los Archivos.<br>' + filesErrors.join('<br>')
                                            });
                                        }
                                    }
                                }, false);

                                ajax.addEventListener('error', function () {
                                    document.querySelector('.app-toast.uploading span.file-' + c).innerText = 'Error';
                                }, false);

                                ajax.open('POST', settings.restUrl + '/application/' + settings.trip.application.application_id + '/upload');
                                ajax.setRequestHeader('X-WETAG-Token', settings.token);
                                ajax.send(formData);

                            });
                        });


                        // PDFit
                        filesUploading
                            .then(function () {
                                page.classList.remove('app-block-touch')
                                document.querySelector('.app-toast.uploading').remove();

                                fetch(settings.restUrl + '/application/' + settings.trip.application.application_id + '/pdfit', {
                                    method: 'POST',
                                    headers: {
                                        'X-WETAG-Token': settings.token,
                                        'Content-Type': 'application/x-www-form-urlencoded'
                                    },
                                    body: new URLSearchParams({
                                        member_id: settings.member.member_id,
                                        filename: data.name,
                                        step_file_id: data.step_file_id
                                    })
                                })
                                    .then((response) => {
                                        return response.json();
                                    })
                                    .then((response) => {
                                        App.load('application', { tripId: db().getCurrentTripId() });
                                        toast().show(response.html, response.class);
                                    })
                                    .catch(error => {
                                        console.log(error);
                                    });
                            })
                            .catch(function (response) {
                                App.load('application', { tripId: db().getCurrentTripId() });
                                toast().show(response.html, response.class);
                            });
                    } else {
                        isUploading = false;
                        page.classList.remove('app-block-touch')
                    }
                });

        } else {
            // Does Not Require Upload
            page.querySelectorAll('.upload-file-trigger, .upload-step-file, .upload-files-list')
                .forEach((el) => { el.remove() });
            page.querySelector('.app-bottom-nav .app-nav').classList.add('app-nav-xs');
        }

        // Photo Example
        if (data.example) {
            page.querySelector(".upload-example")
                .innerHTML = '<img src="' + settings.contentUrl + '/others/actions/' + data.example + '" class="img-fluid rounded">';
        } else {
            page.querySelector(".upload-example").remove();
        }
    });
});
