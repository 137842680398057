import { auth } from '../libraries/auth';

import { hideLoader, tools } from '../libraries/tools';

import gtag from 'ga-gtag';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

import Columns from 'columns.js';
import PullToRefresh from 'pulltorefreshjs';

App.controller('content', function (page, data) {
    auth().checkToken();

    page.addEventListener('appReady', function () {
        gtag('event', 'screen_view', { screen_name: 'Explorar' });
        tools().showSupportNav(page);

        let listContent = page.querySelector('.content-list');
        if (navigator.onLine) {
            var fetchPostsHeaders = new Headers();
            fetchPostsHeaders.append('pragma', 'no-cache');
            fetchPostsHeaders.append('cache-control', 'no-cache');
            fetch('https://wetag.ar/wp-json/wp/v2/posts?categories=21&per_page=50&_embed&_' + new Date().getTime())
                .then((response) => {
                    return response.json();
                })
                .then((posts) => {
                    if (posts.length > 0) {
                        posts.forEach((post, i) => {
                            let coverSizes = false;
                            if (
                                'wp:featuredmedia' in post._embedded
                                && post._embedded['wp:featuredmedia'].length > 0
                                && post._embedded['wp:featuredmedia'][0].media_details.sizes
                                && 'media_details' in post._embedded['wp:featuredmedia'][0]) {
                                coverSizes = post._embedded['wp:featuredmedia'][0].media_details.sizes;
                            }

                            let cover = false;
                            if (coverSizes) {
                                if ('medium' in coverSizes) {
                                    cover = coverSizes.medium.source_url;
                                } else {
                                    cover = coverSizes.full.source_url;
                                }
                            }

                            let item = document.createElement('section');
                            item.className = 'post drop-shadow-dark-left mb-3';
                            item.innerHTML = '<h3 class="m-0 p-2 p-md-4 text-white">' + post.title.rendered + '</h3>';

                            if (cover) item.style.backgroundImage = `url(${cover})`;

                            item.setAttribute('data-args', JSON.stringify({
                                title: post.title.rendered,
                                excerpt: post.excerpt.rendered,
                                content: post.content.rendered,
                                date: post.modified,
                                cover: cover,
                                acf: post.acf
                            }));

                            item.addEventListener('click', function () {
                                App.load('reader', JSON.parse(this.dataset.args))
                            });
                            listContent.append(item);

                            if (i + 1 === posts.length) hideLoader(page.querySelector('.app-loader'));
                        });
                    }
                })
                .then(function () {
                    let grid = new Columns(listContent, {
                        columns: 2
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        }
        
        // Pull to Refresh
        PullToRefresh.init({
            mainElement: '.app-content',
            triggerElement: '.page-content',
            instructionsPullToRefresh: 'Arrastrá hacia abajo para actualizar',
            instructionsReleaseToRefresh: 'Soltá para actualizar',
            instructionsRefreshing: 'Actualizando',
            onRefresh() {
                window.location.reload();
            },
            shouldPullToRefresh: function () {
                return !this.mainElement.scrollTop;
            }
        });
    });
});
