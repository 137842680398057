var toast = function () {
    return {
        show: function (
            html = '',
            type = 'success',
            permanent = false, // or Delay
            click = 'exit',
            classes = false
        ) {
            const effectIn = 'animate__flipInX';
            const effectOut = 'animate__flipOutX';

            let toast = document.createElement('div');
            toast.className = 'animate__animated ' + effectIn + ' app-toast ' + type;
            toast.innerHTML = html;

            let words = html.split(' ').length * 250;
            if (words < 4000) words = 4000;
            let time = Number.isInteger(permanent) ? permanent : words;

            if (classes) {
                classes.split(' ').forEach((className) => {
                    toast.classList.add(className);
                });
            }

            if (click) {
                if (click === 'exit') {
                    toast.addEventListener('click', function (e) {
                        e.stopPropagation();
                        this.classList.remove(effectIn);
                        this.classList.add(effectOut);
                        this.addEventListener('animationend', (e) => { e.target.remove() });
                    });
                } else {
                    toast.addEventListener('click', function (e) {
                        e.stopPropagation();
                        click();
                    });
                }
            }

            if (permanent === false || Number.isInteger(permanent)) {
                setTimeout(() => {
                    toast.classList.remove(effectIn);
                    toast.classList.add(effectOut);
                    toast.addEventListener('animationend', (e) => e.target.remove());
                }, time);
            }

            document.querySelector('.app-toaster').append(toast);

            return toast;
        },
    };
};

export { toast }