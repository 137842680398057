import { Swapper, Scrollable } from "./appjs.library.js";

// APP
window.App = (function (OldApp) {
    var App = {
        noConflict: noConflict,
    };
    return App;

    function noConflict() {
        if (window.App === App) {
            window.App = OldApp;
        }
        return App;
    }
})(window.App);

// UTILS
App._Utils = (function (window, document, App) {
    var query = (function (queryString) {
        var re = /([^&=]+)=([^&]+)/g,
            decodedSpace = /\+/g;

        var result = {},
            m,
            key,
            value;

        if (queryString) {
            queryString = queryString.replace(decodedSpace, "%20");

            while ((m = re.exec(queryString))) {
                key = decodeURIComponent(m[1]);
                value = decodeURIComponent(m[2]);
                result[key] = value;
            }
        }

        return result;
    })(window.location.href.split("?")[1]);

    var os = (function (userAgent) {
        var name, match;

        if ((match = /\bCPU.*OS (\d+(_\d+)?)/i.exec(userAgent))) {
            name = "ios";
        } else if ((match = /\bAndroid (\d+(\.\d+)?)/.exec(userAgent))) {
            name = "android";
        } else {
            name = "desktop";
        }

        var data = {
            name: name
        };

        data[name] = true;
        document.body.className += " app-" + name;

        return data;
    })(navigator.userAgent);

    var forEach = (function (forEach) {
        if (forEach) {
            return function (arr, callback, self) {
                return forEach.call(arr, callback, self);
            };
        } else {
            return function (arr, callback, self) {
                for (var i = 0, len = arr.length; i < len; i++) {
                    if (i in arr) {
                        callback.call(self, arr[i], i, arr);
                    }
                }
            };
        }
    })(Array.prototype.forEach);

    function isArray(arr) {
        if (Array.isArray) {
            return Array.isArray(arr);
        } else {
            return Object.prototype.toString.call(arr) !== "[object Array]";
        }
    }

    function isNode(elem) {
        if (!elem) {
            return false;
        }

        try {
            return elem instanceof Node || elem instanceof HTMLElement;
        } catch (err) { }

        if (typeof elem !== "object") {
            return false;
        }

        if (typeof elem.nodeType !== "number") {
            return false;
        }

        if (typeof elem.nodeName !== "string") {
            return false;
        }

        return true;
    }

    function onReady(func) {
        if (document.readyState === "complete") {
            setTimeout(function () {
                func();
            }, 0);
            document.dispatchEvent(new CustomEvent('appReady'));
            return;
        }


        window.addEventListener("load", runCallback, { passive: true });

        function runCallback() {
            window.removeEventListener("load", runCallback);

            setTimeout(function () {
                func();
            }, 0);
        }
    }

    var queueAnimation = (function () {
        var animationQueue;
        return queueAnimation;

        function queueAnimation(func) {
            if (animationQueue) {
                animationQueue.push(func);
            } else {
                animationQueue = [func];
                foregroundFlush();
            }
        }

        function foregroundFlush() {
            if (
                typeof kik === "object" &&
                typeof kik.browser === "object" &&
                kik.browser.background &&
                typeof kik.browser.once === "function"
            ) {
                kik.browser.once("foreground", flushAnimations);
            } else {
                flushAnimations();
            }
        }

        function flushAnimations() {
            var anim = animationQueue.shift();
            if (anim) {
                onReady(function () {
                    var unlocked = false;
                    function unlock() {
                        // prevent unlocking mult. times
                        if (unlocked) {
                            return;
                        }
                        unlocked = true;
                        setTimeout(foregroundFlush, 0);
                    }
                    anim(unlock);
                });
            } else {
                animationQueue = null;
            }
        }
    })();

    function setTransform(elem, transform) {
        elem.style["-webkit-transform"] = transform;
        elem.style["-moz-transform"] = transform;
        elem.style["-ms-transform"] = transform;
        elem.style["-o-transform"] = transform;
        elem.style["transform"] = transform;
    }

    function setTransition(elem, transition) {
        if (transition) {
            elem.style["-webkit-transition"] = "-webkit-" + transition;
            elem.style["-moz-transition"] = "-moz-" + transition;
            elem.style["-ms-transition"] = "-ms-" + transition;
            elem.style["-o-transition"] = "-o-" + transition;
            elem.style["transition"] = transition;
        } else {
            elem.style["-webkit-transition"] = "";
            elem.style["-moz-transition"] = "";
            elem.style["-ms-transition"] = "";
            elem.style["-o-transition"] = "";
            elem.style["transition"] = "";
        }
    }

    function getStyles(elem, notComputed) {
        var styles;

        if (notComputed) {
            styles = elem.style;
        } else {
            styles = document.defaultView.getComputedStyle(elem, null);
        }

        return {
            display: styles.display,
            opacity: styles.opacity,
            paddingRight: styles.paddingRight,
            paddingLeft: styles.paddingLeft,
            marginRight: styles.marginRight,
            marginLeft: styles.marginLeft,
            borderRightWidth: styles.borderRightWidth,
            borderLeftWidth: styles.borderLeftWidth,
            top: styles.top,
            left: styles.left,
            height: styles.height,
            width: styles.width,
            position: styles.position,
        };
    }

    function isVisible(elem) {
        var styles = getStyles(elem);
        return styles.display !== "none" && styles.opacity !== "0";
    }

    // this is tuned for use with the iOS transition
    // be careful if using this elsewhere
    function transitionElems(transitions, timeout, easing, callback) {
        if (typeof transitions.length !== "number") {
            transitions = [transitions];
        }

        var opacities = transitions.map(function (transition) {
            return transition.elem.style.opacity;
        });

        setInitialStyles(function () {
            animateElems(function () {
                restoreStyles(function () {
                    callback();
                });
            });
        });

        function setInitialStyles(callback) {
            forEach(transitions, function (transition) {
                if (typeof transition.transitionStart !== "undefined") {
                    setTransform(transition.elem, transition.transitionStart);
                }
                if (typeof transition.opacityStart !== "undefined") {
                    transition.elem.style.opacity = transition.opacityStart + "";
                }
            });

            setTimeout(function () {
                forEach(transitions, function (transition) {
                    var e = transition.easing || easing,
                        transitionString =
                            "transform " +
                            timeout / 1000 +
                            "s " +
                            e +
                            ", opacity " +
                            timeout / 1000 +
                            "s " +
                            e;
                    setTransition(transition.elem, transitionString);
                });

                setTimeout(callback, 0);
            }, 0);
        }

        function animateElems(callback) {
            forEach(transitions, function (transition) {
                if (typeof transition.transitionEnd !== "undefined") {
                    setTransform(transition.elem, transition.transitionEnd);
                }
                if (typeof transition.opacityEnd !== "undefined") {
                    transition.elem.style.opacity = transition.opacityEnd + "";
                }
            });

            var lastTransition = transitions[transitions.length - 1];
            lastTransition.elem.addEventListener(
                "webkitTransitionEnd",
                transitionFinished,
                false
            );
            lastTransition.elem.addEventListener(
                "transitionend",
                transitionFinished,
                false
            );
            lastTransition.elem.addEventListener(
                "onTransitionEnd",
                transitionFinished,
                false
            );
            lastTransition.elem.addEventListener(
                "ontransitionend",
                transitionFinished,
                false
            );
            lastTransition.elem.addEventListener(
                "MSTransitionEnd",
                transitionFinished,
                false
            );
            lastTransition.elem.addEventListener(
                "transitionend",
                transitionFinished,
                false
            );

            var done = false;

            function transitionFinished(e) {
                if (done || e.target !== lastTransition.elem) {
                    return;
                }
                done = true;

                forEach(transitions, function (transition) {
                    lastTransition.elem.removeEventListener(
                        "webkitTransitionEnd",
                        transitionFinished
                    );
                    lastTransition.elem.removeEventListener(
                        "transitionend",
                        transitionFinished
                    );
                    lastTransition.elem.removeEventListener(
                        "onTransitionEnd",
                        transitionFinished
                    );
                    lastTransition.elem.removeEventListener(
                        "ontransitionend",
                        transitionFinished
                    );
                    lastTransition.elem.removeEventListener(
                        "MSTransitionEnd",
                        transitionFinished
                    );
                    lastTransition.elem.removeEventListener(
                        "transitionend",
                        transitionFinished
                    );
                });

                callback();
            }
        }

        function restoreStyles(callback) {
            forEach(transitions, function (transition) {
                setTransition(transition.elem, "");
            });

            setTimeout(function () {
                forEach(transitions, function (transition, i) {
                    setTransform(transition.elem, "");
                    transition.elem.style.opacity = opacities[i];
                });

                callback();
            }, 0);
        }
    }

    App.platform = os.name;
    App.queue = queueAnimation;

    return {
        query: query,
        os: os,
        ready: onReady,
        forEach: forEach,
        isArray: isArray,
        isNode: isNode,
        setTransform: setTransform,
        setTransition: setTransition,
        animate: transitionElems,
        getStyles: getStyles,
        isVisible: isVisible,
    };
})(window, document, App);

// EVENTS
App._Events = (function (Utils) {
    var APPJS_EVENTS_VAR = "__appjsCustomEventing";

    var hasCustomEvents = supportsCustomEventing();

    return {
        init: setupCustomEventing,
        fire: fireEvent,
    };

    function supportsCustomEventing() {
        try {
            var elem = document.createElement("div");
            var evt = new CustomEvent("fooBarFace", { bubbles: false, cancelable: true });
            elem.dispatchEvent(evt);
            return true;
        } catch (err) {
            return false;
        }
    }

    function setupCustomEventing(elem, names) {
        if (hasCustomEvents) {
            return;
        }

        if (elem[APPJS_EVENTS_VAR]) {
            Utils.forEach(names, elem[APPJS_EVENTS_VAR].addEventType);
            return;
        }

        elem[APPJS_EVENTS_VAR] = {
            fire: fireElemEvent,
            addEventType: addEventType,
            addEventListener: elem.addEventListener,
            removeEventListener: elem.removeEventListener,
        };

        var listeners = {};
        Utils.forEach(names, function (name) {
            listeners[name] = [];
        });

        function addEventType(name) {
            if (names.indexOf(name) !== -1) {
                return;
            }
            names.push(name);
            listeners[name] = [];
        }

        function fireElemEvent(name) {
            if (names.indexOf(name) === -1) {
                return false;
            }

            var prevented = false,
                evt = {
                    preventDefault: function () {
                        prevented = true;
                    },
                };

            Utils.forEach(listeners[name], function (listener) {
                setTimeout(function () {
                    if (listener.call(elem, evt) === false) {
                        prevented = true;
                    }
                }, 0);
            });

            return !prevented;
        }

        elem.addEventListener = function (name, listener) {
            if (names.indexOf(name) === -1) {
                elem[APPJS_EVENTS_VAR].addEventListener.apply(this, arguments);
                return;
            }

            var eventListeners = listeners[name];

            if (eventListeners.indexOf(listener) === -1) {
                eventListeners.push(listener);
            }
        };

        elem.removeEventListener = function (name, listener) {
            if (names.indexOf(name) === -1) {
                elem[APPJS_EVENTS_VAR].removeEventListener.apply(this, arguments);
                return;
            }

            var eventListeners = listeners[name],
                index = eventListeners.indexOf(listener);

            if (index !== -1) {
                eventListeners.splice(index, 1);
            }
        };
    }

    function fireEvent(elem, eventName) {
        //if (eventName === )
        if (elem[APPJS_EVENTS_VAR]) {
            return elem[APPJS_EVENTS_VAR].fire(eventName);
        } else {
            var evt = new CustomEvent(eventName, { bubbles: false, cancelable: true });
            return elem.dispatchEvent(evt);
        }
    }
})(App._Utils);

// DIALOG
App._Dialog = (function (window, document, App, Utils) {
    var DIALOG_INDICATOR_CLASS = "app-dialog-visible";

    var currentCallback, dialogQueue;

    App.dialog = function (options, callback) {
        if (typeof options !== "object" || options === null) {
            throw TypeError("dialog options must be an object, got " + options);
        }
        switch (typeof options.text) {
            case "undefined":
            case "string":
                break;
            default:
                if (!Utils.isNode(options.text)) {
                    throw TypeError(
                        "dialog text must be a string if defined, got " + options.text
                    );
                }
        }
        for (var key in options) {
            if (
                key === "title" ||
                key.substr(key.length - 6) === "Button"
            ) {
                switch (typeof options[key]) {
                    case "undefined":
                    case "string":
                        break;
                    default:
                        throw TypeError(
                            "dialog button (" + key + ") must be a string if defined, got " + options[key]
                        );
                }
            }
        }
        switch (typeof callback) {
            case "undefined":
                callback = function () { };
            case "function":
                break;
            default:
                throw TypeError(
                    "callback must be a function if defined, got " + callback
                );
        }

        return showDialog(options, callback);
    };

    App.dialog.close = function (status) {
        return closeDialog(status || false);
    };

    App.dialog.status = function () {
        return hasDialog();
    };

    return App.dialog;

    function createDialog(options, callback) {
        var dialogContainer = document.createElement("div");
        dialogContainer.className += " app-dialog-container";
        if (!Utils.os.android) {
            dialogContainer.addEventListener(
                "touchstart",
                function (e) {
                    if (e.target === dialogContainer) {
                        e.preventDefault();
                    }
                },
                { passive: true }
            );
        }

        var dialog = document.createElement("div");
        dialog.className = "app-dialog bg-white rounded overflow-hidden";
        dialog.setAttribute('style', 'transform: translate3d(0, ' + Math.round(window.innerHeight / 1.75) + 'px, 0)');
        dialogContainer.appendChild(dialog);

        if (options.title) {
            var title = document.createElement("div");
            title.className = "dialog-title p-3 pb-0 fs-6 fw-bold text-center";
            title.textContent = options.title;
            dialog.appendChild(title);
        }

        if (options.text || options.rawText) {
            var text = document.createElement("div");
            text.className = "dialog-text px-3 pt-2 text-center fw-semibold";
            if (Utils.isNode(options.text)) {
                text.appendChild(options.text);
            } else if (options.rawText) {
                text.innerHTML = options.rawText;
            } else {
                text.textContent = options.text;
            }
            dialog.appendChild(text);
        }

        if (options.rawHTML) {
            dialog.appendChild(options.rawHTML);
        }

        if (options.okButton || options.cancelButton) {
            var nav = document.createElement("div");
            nav.className = "dialog-nav app-nav mt-3";

            if (options.cancelButton) {
                var button = document.createElement("div");
                button.className = "app-button bg-gray";
                button.setAttribute("data-button", "cancel");
                button.textContent = options.cancelButton;
                button.addEventListener("click", handleChoice, false);
                nav.appendChild(button);
            }

            if (options.okButton) {
                var button = document.createElement("div");
                button.className = "app-button bg-green text-white";
                button.setAttribute("data-button", "ok");
                button.textContent = options.okButton;
                button.addEventListener("click", handleChoice, false);
                nav.appendChild(button);
            }

            dialog.appendChild(nav);
        } else {
            dialog.className += ' pb-3';
        }

        function handleChoice() {
            var buttonName = this.getAttribute("data-button");
            if (buttonName === "cancel") {
                buttonName = false;
            }
            callback(buttonName);
        }

        return dialogContainer;
    }

    function showDialog(options, callback, force) {
        if (dialogQueue && !force) {
            dialogQueue.push([options, callback]);
            return;
        }
        dialogQueue = dialogQueue || [];

        var dialogLock = false,
            dialog = createDialog(options, dialogClosed),
            innerDialog = dialog.firstChild;
        currentCallback = dialogClosed;

        Utils.ready(function () {
            document.body.appendChild(dialog);
            setTimeout(function () {
                dialog.className += " enabled";
                document.body.className += " " + DIALOG_INDICATOR_CLASS;
            }, 50);
        });

        function dialogClosed(status) {
            if (dialogLock) {
                return;
            }
            dialogLock = true;

            currentCallback = null;

            dialog.className =
                dialog.className.replace(/\benabled\b/g, "") + " closing";
            if (status) {
                dialog.className += " closing-success";
            } else {
                dialog.className += " closing-fail";
            }
            document.body.className = document.body.className.replace(
                new RegExp("\\b" + DIALOG_INDICATOR_CLASS + "\\b", "g"),
                ""
            );

            setTimeout(function () {
                processDialogQueue();
                callback(status);
            }, 0);

            setTimeout(function () {
                try {
                    dialog.parentNode.removeChild(dialog);
                } catch (err) { }
            }, 600);

            return true;
        }
    }

    function closeDialog(status) {
        if (currentCallback) {
            return currentCallback(status || false);
        }
    }

    function hasDialog() {
        return !!currentCallback;
    }

    function processDialogQueue() {
        if (!dialogQueue) {
            return;
        }

        if (!dialogQueue.length) {
            dialogQueue = null;
            return;
        }

        var args = dialogQueue.shift();
        args.push(true);
        showDialog.apply(window, args);
    }
})(window, document, App, App._Utils);

// SCROLL
App._Scroll = (function (Scrollable, App, Utils) {
    var TAGS = {
        APP_CONTENT: "app-content",
        APP_SCROLLABLE: "app-scrollable",
        NO_SCROLL: "data-no-scroll",
        SCROLLABLE: "data-scrollable",
        LAST_SCROLL: "data-last-scroll",
        SCROLL_STYLE: "data-scroll-style",
        TOUCH_SCROLL: "-webkit-overflow-scrolling",
    },
        PAGE_MANAGER_VAR = "__appjsPageManager";

    return {
        setup: setupScrollers,
        disable: disableScrolling,
        saveScrollPosition: savePageScrollPosition,
        saveScrollStyle: savePageScrollStyle,
        restoreScrollPosition: restorePageScrollPosition,
        restoreScrollStyle: restorePageScrollStyle,
    };

    function setupScrollers(page) {
        Utils.forEach(page.querySelectorAll("." + TAGS.APP_CONTENT), function (
            content
        ) {
            if (content.getAttribute(TAGS.NO_SCROLL) === null) {
                setupScroller(content);
            }
        });

        Utils.forEach(page.querySelectorAll("[" + TAGS.SCROLLABLE + "]"), function (
            content
        ) {
            setupScroller(content);
        });
    }

    function setupScroller(content) {
        var forceIScroll = !!window["APP_FORCE_ISCROLL"];
        Scrollable(content, forceIScroll);
        content.className += " " + TAGS.APP_SCROLLABLE;
    }

    function disableScrolling(page) {
        Utils.forEach(page.querySelectorAll("*"), function (elem) {
            elem.style[TAGS.TOUCH_SCROLL] = "";
        });
    }

    function getScrollableElems(page) {
        var elems = [];

        if (page) {
            Utils.forEach(page.querySelectorAll("." + TAGS.APP_SCROLLABLE), function (
                elem
            ) {
                if (elem._scrollable) {
                    elems.push(elem);
                }
            });
        }

        return elems;
    }

    function savePageScrollPosition(page) {
        Utils.forEach(getScrollableElems(page), function (elem) {
            if (elem._iScroll) {
                return;
            }

            var scrollTop = elem._scrollTop();
            elem.setAttribute(TAGS.LAST_SCROLL, scrollTop + "");
        });
    }

    function savePageScrollStyle(page) {
        Utils.forEach(getScrollableElems(page), function (elem) {
            if (elem._iScroll) {
                return;
            }

            var scrollStyle = elem.style[TAGS.TOUCH_SCROLL] || "";
            elem.style[TAGS.TOUCH_SCROLL] = "";
            elem.setAttribute(TAGS.SCROLL_STYLE, scrollStyle);
        });
    }

    function restorePageScrollPosition(page, noTimeout) {
        Utils.forEach(getScrollableElems(page), function (elem) {
            if (elem._iScroll) {
                return;
            }

            var scrollTop = parseInt(elem.getAttribute(TAGS.LAST_SCROLL));

            if (scrollTop) {
                if (!noTimeout) {
                    setTimeout(function () {
                        elem._scrollTop(scrollTop);
                    }, 0);
                } else {
                    elem._scrollTop(scrollTop);
                }
            }
        });
    }

    function restorePageScrollStyle(page) {
        Utils.forEach(getScrollableElems(page), function (elem) {
            if (elem._iScroll) {
                return;
            }

            var scrollStyle = elem.getAttribute(TAGS.SCROLL_STYLE) || "";

            if (scrollStyle) {
                elem.style[TAGS.TOUCH_SCROLL] = scrollStyle;
            }
        });

        restorePageScrollPosition(page, true);
    }

    function getParentPage(elem) {
        var parent = elem;
        do {
            if (/\bapp\-page\b/.test(parent.className)) {
                return parent;
            }
        } while ((parent = parent.parentNode));
    }

    function getParentScroller(elem) {
        var parent = elem;
        do {
            if (parent._scrollable || /\bapp\-content\b/.test(parent.className)) {
                return parent;
            }
        } while ((parent = parent.parentNode));
    }

    function getPageManager(page) {
        if (page) {
            return page[PAGE_MANAGER_VAR];
        }
    }
})(Scrollable, App, App._Utils);

// PAGES
App._Pages = (function (window, document, App, Utils, Events, Scroll) {
    var PAGE_NAME = "data-page",
        PAGE_CLASS = "app-page",
        APP_LOADED = "app-loaded",
        PAGE_READY_VAR = "__appjsFlushReadyQueue",
        PAGE_MANAGER_VAR = "__appjsPageManager",
        EVENTS = {
            SHOW: "show",
            HIDE: "hide",
            BACK: "back",
            FORWARD: "forward",
            BEFORE_BACK: "beforeBack",
            READY: "ready",
            DESTROY: "destroy",
            LAYOUT: "layout",
            ONLINE: "online",
            OFFLINE: "offline",
        };

    var preloaded = false,
        //    forceIScroll = !!window["APP_FORCE_ISCROLL"],
        pages = {},
        controllers = {},
        cleanups = {};

    setupPageListeners();

    App.add = function (pageName, page) {
        if (typeof pageName !== "string") {
            page = pageName;
            pageName = undefined;
        }

        if (!Utils.isNode(page)) {
            throw TypeError("page template node must be a DOM node, got " + page);
        }

        addPage(page, pageName);
    };

    App.controller = function (pageName, controller, cleanup) {
        if (typeof pageName !== "string") {
            throw TypeError("page name must be a string, got " + pageName);
        }

        if (typeof controller !== "function") {
            throw TypeError("page controller must be a function, got " + controller);
        }

        switch (typeof cleanup) {
            case "undefined":
                cleanup = function () { };
                break;

            case "function":
                break;

            default:
                throw TypeError(
                    "page cleanup handler must be a function, got " + cleanup
                );
        }

        if (controller) {
            addController(pageName, controller);
        }
        if (cleanup) {
            addCleanup(pageName, cleanup);
        }
    };

    App.generate = function (pageName, args) {
        if (typeof pageName !== "string") {
            throw TypeError("page name must be a string, got " + pageName);
        }

        switch (typeof args) {
            case "undefined":
                args = {};
                break;

            case "object":
                break;

            default:
                throw TypeError(
                    "page arguments must be an object if defined, got " + args
                );
        }

        return generatePage(pageName, args);
    };

    App.destroy = function (page) {
        if (!Utils.isNode(page)) {
            throw TypeError("page node must be a DOM node, got " + page);
        }

        return destroyPage(page);
    };

    App._layout = triggerPageSizeFix;

    return {
        EVENTS: EVENTS,
        has: hasPage,
        createManager: createPageManager,
        startGeneration: startPageGeneration,
        finishGeneration: finishPageGeneration,
        fire: firePageEvent,
        startDestruction: startPageDestruction,
        finishDestruction: finishPageDestruction,
        fixContent: fixContentHeight,
        populateBackButton: populatePageBackButton,
    };

    /* Page elements */
    function preloadPages() {
        if (preloaded) {
            return;
        }
        preloaded = true;

        var pageNodes = document.getElementsByClassName(PAGE_CLASS);

        for (var i = pageNodes.length; i--;) {
            addPage(pageNodes[i]);
        }

        document.body.className += " " + APP_LOADED;
    }

    function addPage(page, pageName) {
        if (!pageName) {
            pageName = page.getAttribute(PAGE_NAME);
        }

        if (!pageName) {
            throw TypeError("page name was not specified");
        }

        page.setAttribute(PAGE_NAME, pageName);
        if (page.parentNode) {
            page.parentNode.removeChild(page);
        }
        pages[pageName] = page.cloneNode(true);
    }

    function hasPage(pageName) {
        preloadPages();
        return pageName in pages;
    }

    function clonePage(pageName) {
        if (!hasPage(pageName)) {
            throw TypeError(pageName + " is not a known page");
        }
        return pages[pageName].cloneNode(true);
    }

    /* Page controllers */
    function addController(pageName, controller) {
        controllers[pageName] = controller;
    }

    function addCleanup(pageName, cleanup) {
        cleanups[pageName] = cleanup;
    }

    function populatePage(pageName, pageManager, page, args) {
        var controller = controllers[pageName];
        if (!controller) {
            return;
        }
        for (var prop in controller) {
            pageManager[prop] = controller[prop];
        }
        for (var prop in controller.prototype) {
            pageManager[prop] = controller.prototype[prop];
        }
        pageManager.page = page; //TODO: getter
        pageManager.args = args; //TODO: getter (dont want this to hit localStorage)
        controller.call(pageManager, page, args);
    }

    function unpopulatePage(pageName, pageManager, page, args) {
        var cleanup = cleanups[pageName];
        if (cleanup) {
            cleanup.call(pageManager, page, args);
        }
        firePageEvent(pageManager, page, EVENTS.DESTROY);
    }

    /* Page generation */
    function createPageManager(restored) {
        var pageManager = {
            restored: restored,
            showing: false,
            online: navigator.onLine,
        };

        var readyQueue = [];

        pageManager.ready = function (func) {
            if (typeof func !== "function") {
                throw TypeError("ready must be called with a function, got " + func);
            }

            if (readyQueue) {
                readyQueue.push(func);
            } else {
                func.call(pageManager);
            }
        };

        pageManager[PAGE_READY_VAR] = function () {
            Utils.ready(function () {
                if (!readyQueue) {
                    return;
                }
                var queue = readyQueue.slice();
                readyQueue = null;
                if (Utils.isNode(pageManager.page)) {
                    firePageEvent(pageManager, pageManager.page, EVENTS.READY);
                }
                Utils.forEach(queue, function (func) {
                    func.call(pageManager);
                });
            });
        };

        return pageManager;
    }

    function generatePage(pageName, args) {
        var pageManager = {},
            page = startPageGeneration(pageName, pageManager, args);

        finishPageGeneration(pageName, pageManager, page, args);

        return page;
    }

    function destroyPage(page) {
        var pageName = page.getAttribute(PAGE_NAME);
        startPageDestruction(pageName, {}, page, {});
        finishPageDestruction(pageName, {}, page, {});
    }

    function startPageGeneration(pageName, pageManager, args) {
        var page = clonePage(pageName);

        var eventNames = [];
        for (var evt in EVENTS) {
            eventNames.push(eventTypeToName(EVENTS[evt]));
        }
        Events.init(page, eventNames);

        page[PAGE_MANAGER_VAR] = pageManager;

        fixContentHeight(page);

        Utils.forEach(page.querySelectorAll('[data-target]'), attachButtonEvent);

        var observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                if (mutation.type == 'childList') {
                    if (mutation.addedNodes.length >= 1) {
                        for (var i = 0; i < mutation.addedNodes.length; i++) {
                            let element = mutation.addedNodes[i];
                            if (element.dataset && element.dataset.hasOwnProperty('target')) {
                                attachButtonEvent(element);
                            }
                        }
                    }

                }
            });
        });
        observer.observe(page, { attributes: true, childList: true, characterData: true, subtree: true });

        populatePage(pageName, pageManager, page, args);

        page.addEventListener(
            eventTypeToName(EVENTS.SHOW),
            function () {
                setTimeout(function () {
                    if (typeof pageManager[PAGE_READY_VAR] === "function") {
                        pageManager[PAGE_READY_VAR]();
                    }
                }, 0);
            },
            false
        );

        return page;
    }

    function attachButtonEvent(button) {
        if (button.getAttribute("data-no-click") !== null) {
            return;
        }

        button.addEventListener(
            "click",
            function () {
                var target = button.getAttribute("data-target"),
                    targetArgs = button.getAttribute("data-target-args"),
                    args;

                try {
                    args = JSON.parse(targetArgs);
                } catch (err) { }
                if (typeof args !== "object" || args === null) {
                    args = {};
                }
                if (!target) {
                    return;
                }

                var clickableClass = button.getAttribute("data-clickable-class");
                if (clickableClass) {
                    button.disabled = true;
                    button.classList.add(clickableClass);
                }

                if (target === 'back') {
                    App.back(finish);
                } else if (target) {
                    App.load(target, args, {}, finish);
                }

                function finish() {
                    if (clickableClass) {
                        button.disabled = false;
                        button.classList.remove(clickableClass);
                    }
                }
            },
            false
        );
    }

    function firePageEvent(pageManager, page, eventType) {
        var eventName = eventTypeToName(eventType),
            funcName = eventTypeToFunctionName(eventType),
            success = true;

        if (!Events.fire(page, eventName)) {
            success = false;
        }
        if (typeof pageManager[funcName] === "function") {
            if (pageManager[funcName]() === false) {
                success = false;
            }
        }

        if (success) 
        return success;
    }

    function eventTypeToName(eventType) {
        return "app" + eventType[0].toUpperCase() + eventType.slice(1);
    }

    function eventTypeToFunctionName(eventType) {
        return "on" + eventType[0].toUpperCase() + eventType.slice(1);
    }

    function finishPageGeneration(pageName, pageManager, page, args) {
        Scroll.setup(page);
    }

    function startPageDestruction(pageName, pageManager, page, args) {
        if (!Utils.os.ios) {
            Scroll.disable(page);
        }
        if (typeof pageManager.reply === "function") {
            pageManager._appNoBack = true;
            pageManager.reply();
        }
    }

    function finishPageDestruction(pageName, pageManager, page, args) {
        unpopulatePage(pageName, pageManager, page, args);
    }

    /* Page layout */
    function setupPageListeners() {
        window.addEventListener("orientationchange", triggerPageSizeFix);
        window.addEventListener("resize", triggerPageSizeFix);
        window.addEventListener("load", triggerPageSizeFix);
        setTimeout(triggerPageSizeFix, 0);

        window.addEventListener(
            "online",
            function () {
                if (App._Stack) {
                    Utils.forEach(App._Stack.get(), function (pageInfo) {
                        pageInfo[2].online = true;
                        firePageEvent(pageInfo[2], pageInfo[3], EVENTS.ONLINE);
                    });
                }
            },
            false
        );
        window.addEventListener(
            "offline",
            function () {
                if (App._Stack) {
                    Utils.forEach(App._Stack.get(), function (pageInfo) {
                        pageInfo[2].online = false;
                        firePageEvent(pageInfo[2], pageInfo[3], EVENTS.OFFLINE);
                    });
                }
            },
            false
        );
    }

    function triggerPageSizeFix() {
        fixContentHeight();
        var pageData;
        if (App._Stack) {
            pageData = App._Stack.getCurrent();
        }
        if (pageData) {
            firePageEvent(pageData[2], pageData[3], EVENTS.LAYOUT);
        }

        //TODO: turns out this isnt all that expensive, but still, lets kill it if we can
        setTimeout(fixContentHeight, 0);
        setTimeout(fixContentHeight, 10);
        setTimeout(fixContentHeight, 100);
    }

    function fixContentHeight(page) {
        if (!page) {
            if (App._Navigation) page = App._Navigation.getCurrentNode();
            if (!page) return;
        }

        var ptr = page.querySelector(".ptr--ptr");
        var bottomNav = page.querySelector(".app-bottom-nav");
        var content = page.querySelector(".app-content");
        var height = window.innerHeight;

        if (!content) return;

        if (!bottomNav && !ptr) {
            content.style.height = height + "px";
            return;
        }

        // Pull To Refresh
        let ptrHeight = 0;
        if (ptr) {
            var ptrStyles = document.defaultView.getComputedStyle(ptrHeight, null);
            ptrHeight = ptrStyles.height ? parseInt(ptrStyles.height) : 0;
        }

        // Bottom Nav
        let bottomNavHeight = 0;
        if (bottomNav) {
            var bottomNavStyles = document.defaultView.getComputedStyle(bottomNav, null);
            bottomNavHeight = bottomNavStyles.height ? parseInt(bottomNavStyles.height) : 0;
            bottomNavHeight += bottomNavStyles.bottom ? parseInt(bottomNavStyles.bottom) : 0;
            bottomNavHeight += bottomNavStyles.borderBottomWidth ? parseInt(bottomNavStyles.borderBottomWidth) : 0;
            bottomNavHeight += bottomNavStyles.borderTopWidth ? parseInt(bottomNavStyles.borderTopWidth) : 0;
        }

        content.style.height = (height + ptrHeight) + "px";
        content.style.paddingBottom = bottomNavHeight + "px";
    }

    function populatePageBackButton(page, oldPage) {
        if (!oldPage) {
            return;
        }
        var backButton = page.querySelector(".app-top-bar .left.app-button"),
            oldTitle = oldPage.querySelector(".app-top-bar .app-title");
        if (
            !backButton ||
            !oldTitle ||
            backButton.getAttribute("data-autotitle") === null
        ) {
            return;
        }
        var oldText = oldTitle.textContent,
            newText = backButton.textContent;
        if (!oldText || newText) {
            return;
        }
        if (oldText.length > 13) {
            oldText = oldText.substr(0, 12) + "..";
        }
        backButton.textContent = oldText;
    }
})(window, document, App, App._Utils, App._Events, App._Scroll);

// STACK
App._Stack = (function (window, document, App, Utils, Scroll, Pages) {
    var STACK_KEY = "__APP_JS_STACK__" + window.location.pathname,
        STACK_TIME = "__APP_JS_TIME__" + window.location.pathname;

    var stack = [];

    App.getStack = function () {
        return fetchStack();
    };

    App.getPage = function (index) {
        var stackSize = stack.length - 1;
        switch (typeof index) {
            case "undefined":
                index = stackSize;
                break;
            case "number":
                if (Math.abs(index) > stackSize) {
                    throw TypeError(
                        "absolute index cannot be greater than stack size, got " + index
                    );
                }
                if (index < 0) {
                    index = stackSize + index;
                }
                break;
            default:
                throw TypeError("page index must be a number if defined, got " + index);
        }
        return fetchPage(index);
    };

    App.removeFromStack = function (startIndex, endIndex) {
        // minus 1 because last item on stack is current page (which is untouchable)
        var stackSize = stack.length - 1;
        switch (typeof startIndex) {
            case "undefined":
                startIndex = 0;
                break;
            case "number":
                if (Math.abs(startIndex) > stackSize) {
                    throw TypeError(
                        "absolute start index cannot be greater than stack size, got " +
                        startIndex
                    );
                }
                if (startIndex < 0) {
                    startIndex = stackSize + startIndex;
                }
                break;
            default:
                throw TypeError(
                    "start index must be a number if defined, got " + startIndex
                );
        }
        switch (typeof endIndex) {
            case "undefined":
                endIndex = stackSize;
                break;
            case "number":
                if (Math.abs(endIndex) > stackSize) {
                    throw TypeError(
                        "absolute end index cannot be greater than stack size, got " +
                        endIndex
                    );
                }
                if (endIndex < 0) {
                    endIndex = stackSize + endIndex;
                }
                break;
            default:
                throw TypeError(
                    "end index must be a number if defined, got " + endIndex
                );
        }
        if (startIndex > endIndex) {
            throw TypeError("start index cannot be greater than end index");
        }

        removeFromStack(startIndex, endIndex);
    };

    App.addToStack = function (index, newPages) {
        // minus 1 because last item on stack is current page (which is untouchable)
        var stackSize = stack.length - 1;
        switch (typeof index) {
            case "undefined":
                index = 0;
                break;
            case "number":
                if (Math.abs(index) > stackSize) {
                    throw TypeError(
                        "absolute index cannot be greater than stack size, got " + index
                    );
                }
                if (index < 0) {
                    index = stackSize + index;
                }
                break;
            default:
                throw TypeError("index must be a number if defined, got " + index);
        }
        if (!Utils.isArray(newPages)) {
            throw TypeError("added pages must be an array, got " + newPages);
        }
        newPages = newPages.slice();
        Utils.forEach(newPages, function (page, i) {
            if (typeof page === "string") {
                page = [page, {}];
            } else if (Utils.isArray(page)) {
                page = page.slice();
            } else {
                throw TypeError(
                    "page description must be an array (page name, arguments), got " +
                    page
                );
            }
            if (typeof page[0] !== "string") {
                throw TypeError("page name must be a string, got " + page[0]);
            }
            switch (typeof page[1]) {
                case "undefined":
                    page[1] = {};
                case "object":
                    break;
                default:
                    throw TypeError(
                        "page arguments must be an object if defined, got " + page[1]
                    );
            }
            switch (typeof page[2]) {
                case "undefined":
                    page[2] = {};
                case "object":
                    break;
                default:
                    throw TypeError(
                        "page options must be an object if defined, got " + page[2]
                    );
            }
            newPages[i] = page;
        });

        addToStack(index, newPages);
    };

    App.saveStack = function () {
        saveStack();
    };

    App.destroyStack = function () {
        destroyStack();
    };

    App.restore = setupRestoreFunction();

    return {
        get: fetchStack,
        getCurrent: fetchLastStackItem,
        getPage: fetchPage,
        pop: popLastStackItem,
        push: pushNewStackItem,
        size: fetchStackSize,
        save: saveStack,
        destroy: destroyStack,
    };

    function saveStack() {
        try {
            var storedStack = [];
            for (var i = 0, l = stack.length; i < l; i++) {
                if (stack[i][4].restorable === false) {
                    break;
                }
                storedStack.push([stack[i][0], stack[i][3], stack[i][2]]);
            }
            localStorage[STACK_KEY] = JSON.stringify(storedStack);
            localStorage[STACK_TIME] = +new Date() + "";
        } catch (err) { }
    }

    function destroyStack() {
        delete localStorage[STACK_KEY];
        delete localStorage[STACK_TIME];
    }

    function fetchStack() {
        return stack.slice().map(reorganisePageData);
    }

    function fetchStackSize() {
        return stack.length;
    }

    function fetchLastStackItem() {
        var pageData = stack[stack.length - 1];
        if (pageData) {
            return reorganisePageData(pageData);
        }
    }

    function popLastStackItem() {
        var pageData = stack.pop();
        if (pageData) {
            return reorganisePageData(pageData);
        }
    }

    function pushNewStackItem(pageData) {
        stack.push([
            pageData[0],
            pageData[3],
            pageData[4],
            pageData[1],
            pageData[2],
        ]);
    }

    function fetchPage(index) {
        var pageData = stack[index];
        if (pageData) {
            return pageData[1];
        }
    }

    function reorganisePageData(pageData) {
        var pageArgs = {};
        for (var key in pageData[3]) {
            pageArgs[key] = pageData[3][key];
        }
        return [pageData[0], pageArgs, pageData[4], pageData[1], pageData[2]];
    }

    // you must manually save the stack if you choose to use this method
    function removeFromStackNow(startIndex, endIndex) {
        var deadPages = stack.splice(startIndex, endIndex - startIndex);

        Utils.forEach(deadPages, function (pageData) {
            Pages.startDestruction(
                pageData[0],
                pageData[4],
                pageData[1],
                pageData[3]
            );
            Pages.finishDestruction(
                pageData[0],
                pageData[4],
                pageData[1],
                pageData[3]
            );
        });
    }

    function removeFromStack(startIndex, endIndex) {
        App._Navigation.enqueue(function (finish) {
            removeFromStackNow(startIndex, endIndex);
            finish();
        });
    }

    // you must manually save the stack if you choose to use this method
    function addToStackNow(index, newPages, restored) {
        var pageDatas = [],
            lastPage;

        Utils.forEach(newPages, function (pageData) {
            var pageManager = Pages.createManager(true),
                page = Pages.startGeneration(pageData[0], pageManager, pageData[1]);

            if (!pageData[2].transition && pageManager.transition) {
                pageData[2].transition = pageManager.transition;
            }

            Pages.populateBackButton(page, lastPage);

            Pages.finishGeneration(pageData[0], pageManager, page, pageData[1]);

            Scroll.saveScrollPosition(page);
            Scroll.saveScrollStyle(page);

            pageDatas.push([
                pageData[0],
                page,
                pageData[2],
                pageData[1],
                pageManager,
            ]);

            lastPage = page;
        });

        pageDatas.unshift(0);
        pageDatas.unshift(index);
        Array.prototype.splice.apply(stack, pageDatas);
    }

    function addToStack(index, newPages) {
        App._Navigation.enqueue(function (finish) {
            addToStackNow(index, newPages);
            finish();
        });
    }

    function setupRestoreFunction(options) {
        var storedStack, lastPage;

        try {
            storedStack = JSON.parse(localStorage[STACK_KEY]);
            storedTime = parseInt(localStorage[STACK_TIME]);
            lastPage = storedStack.pop();
        } catch (err) {
            return;
        }

        if (!lastPage) {
            return;
        }

        return function (options, callback) {
            switch (typeof options) {
                case "function":
                    callback = options;
                case "undefined":
                    options = {};
                case "object":
                    if (options !== null) {
                        break;
                    }
                default:
                    throw TypeError(
                        "restore options must be an object if defined, got " + options
                    );
            }

            switch (typeof callback) {
                case "undefined":
                    callback = function () { };
                case "function":
                    break;
                default:
                    throw TypeError(
                        "restore callback must be a function if defined, got " + callback
                    );
            }

            if (+new Date() - storedTime >= options.maxAge) {
                throw TypeError("restore content is too old");
            }

            if (!Pages.has(lastPage[0])) {
                throw TypeError(lastPage[0] + " is not a known page");
            }

            Utils.forEach(storedStack, function (pageData) {
                if (!Pages.has(pageData[0])) {
                    throw TypeError(pageData[0] + " is not a known page");
                }
            });

            try {
                addToStackNow(0, storedStack, true);
            } catch (err) {
                removeFromStackNow(0, stack.length);
                throw Error("failed to restore stack");
            }

            saveStack();

            try {
                App.load(lastPage[0], lastPage[1], lastPage[2], callback);
            } catch (err) {
                removeFromStackNow(0, stack.length);
                throw Error("failed to restore stack");
            }
        };
    }
})(window, document, App, App._Utils, App._Scroll, App._Pages);

// TRANSITIONS
App._Transitions = (function (window, document, Swapper, App, Utils, Scroll, Pages, Stack) {
    var TRANSITION_CLASS = "app-transition",
        DEFAULT_TRANSITION = "slide-left",
        REVERSE_TRANSITION = {
            instant: "instant",
            fade: "fade",
            "fade-on": "fade-off",
            "fade-off": "fade-on",
            "scale-in": "scale-out",
            "scale-out": "scale-in",
            "rotate-left": "rotate-right",
            "rotate-right": "rotate-left",
            "cube-left": "cube-right",
            "cube-right": "cube-left",
            "swap-left": "swap-right",
            "swap-right": "swap-left",
            "explode-in": "explode-out",
            "explode-out": "explode-in",
            "implode-in": "implode-out",
            "implode-out": "implode-in",
            "slide-left": "slide-right",
            "slide-right": "slide-left",
            "slide-up": "slide-down",
            "slide-down": "slide-up",
            "slideon-left": "slideoff-left",
            "slideon-right": "slideoff-right",
            "slideon-up": "slideoff-up",
            "slideon-down": "slideoff-down",
            "slideoff-left": "slideon-left",
            "slideoff-right": "slideon-right",
            "slideoff-up": "slideon-up",
            "slideoff-down": "slideon-down",
            "glideon-right": "glideoff-right",
            "glideoff-right": "slideon-right",
            "glideon-left": "glideoff-left",
            "glideoff-left": "slideon-left",
            "glideon-down": "glideoff-down",
            "glideoff-down": "slideon-down",
            "glideon-up": "glideoff-up",
            "glideoff-up": "slideon-up",
        },
        WALL_RADIUS = 10;

    var defaultTransition, reverseTransition, dragLock;

    setDefaultTransition(DEFAULT_TRANSITION);

    App.setDefaultTransition = function (transition) {
        if (typeof transition !== "string") {
            throw TypeError(
                "transition must be a string if defined, got " + transition
            );
        }

        if (!(transition in REVERSE_TRANSITION)) {
            throw TypeError("invalid transition type, got " + transition);
        }

        setDefaultTransition(transition);
    };

    App.getDefaultTransition = function () {
        return defaultTransition;
    };

    App.getReverseTransition = function () {
        return reverseTransition;
    };

    return {
        REVERSE_TRANSITION: REVERSE_TRANSITION,
        run: performTransition,
    };

    function setDefaultTransition(transition) {
        defaultTransition = transition;
        reverseTransition = REVERSE_TRANSITION[defaultTransition];
    }

    function performTransition(oldPage, page, options, callback, reverse) {
        if (!options.transition) {
            options.transition = reverse ? reverseTransition : defaultTransition;
        }

        if (!options.duration) {
            options.duration = 425;
        }

        if (!options.easing) {
            options.easing = "cubic-bezier(.4,.6,.42,.97)";
        }

        document.body.className += " " + TRANSITION_CLASS;

        if (options.transition === "instant") {
            Swapper(oldPage, page, options, function () {
                setTimeout(finish, 0);
            });
        } else {
            Swapper(oldPage, page, options, finish);
        }

        function finish() {
            document.body.className = document.body.className.replace(
                new RegExp("\\b" + TRANSITION_CLASS + "\\b"),
                ""
            );
            callback();
        }
    }

    function getBackTransform(backButton, oldButton, toCenter) {
        var fullWidth = backButton.textContent.length * 12,
            oldWidth = oldButton ? oldButton.textContent.length * 15 : 0;
        if (!toCenter) {
            return (oldWidth - window.innerWidth) / 2;
        } else {
            return (window.innerWidth - fullWidth) / 2;
        }
    }

    function getTitleTransform(backButton, toLeft) {
        var fullWidth = 0;
        if (backButton) {
            fullWidth = backButton.textContent.length * 12;
        }
        if (!toLeft) {
            return window.innerWidth / 2;
        } else {
            return (fullWidth - window.innerWidth) / 2;
        }
    }
})(window, document, Swapper, App, App._Utils, App._Scroll, App._Pages, App._Stack);

// NAVIGATION
App._Navigation = (function (window, document, App, Dialog, Scroll, Pages, Stack, Transitions) {
    var navQueue = [],
        navLock = false,
        current,
        currentNode;

    App.current = function () {
        return current;
    };

    App.load = function (pageName, args, options, callback) {
        if (typeof pageName !== "string") {
            throw TypeError("page name must be a string, got " + pageName);
        }
        switch (typeof args) {
            case "function":
                options = args;
                args = {};
            case "string":
                callback = options;
                options = args;
            case "undefined":
                args = {};
            case "object":
                break;
            default:
                throw TypeError(
                    "page arguments must be an object if defined, got " + args
                );
        }
        switch (typeof options) {
            case "function":
                callback = options;
            case "undefined":
                options = {};
            case "object":
                break;
            case "string":
                options = { transition: options };
                break;
            default:
                throw TypeError("options must be an object if defined, got " + options);
        }
        switch (typeof callback) {
            case "undefined":
                callback = function () { };
            case "function":
                break;
            default:
                throw TypeError(
                    "callback must be a function if defined, got " + callback
                );
        }

        return loadPage(pageName, args, options, callback);
    };

    App.back = function (pageName, callback) {
        switch (typeof pageName) {
            case "function":
                callback = pageName;
            case "undefined":
                pageName = undefined;
            case "string":
                break;
            default:
                throw TypeError(
                    "pageName must be a string if defined, got " + pageName
                );
        }
        switch (typeof callback) {
            case "undefined":
                callback = function () { };
            case "function":
                break;
            default:
                throw TypeError(
                    "callback must be a function if defined, got " + callback
                );
        }

        return navigateBack(pageName, callback);
    };

    App.pick = function (pageName, args, options, loadCallback, callback) {
        if (typeof pageName !== "string") {
            throw TypeError("page name must be a string, got " + pageName);
        }
        switch (typeof args) {
            case "function":
                options = args;
                args = {};
            case "string":
                callback = loadCallback;
                loadCallback = options;
                options = args;
            case "undefined":
                args = {};
            case "object":
                break;
            default:
                throw TypeError(
                    "page arguments must be an object if defined, got " + args
                );
        }
        switch (typeof options) {
            case "function":
                callback = loadCallback;
                loadCallback = options;
            case "undefined":
                options = {};
            case "object":
                break;
            case "string":
                options = { transition: options };
                break;
            default:
                throw TypeError("options must be an object if defined, got " + options);
        }
        if (typeof loadCallback !== "function") {
            throw TypeError("callback must be a function, got " + loadCallback);
        }
        switch (typeof callback) {
            case "undefined":
                callback = loadCallback;
                loadCallback = function () { };
            case "function":
                break;
            default:
                throw TypeError("callback must be a function, got " + callback);
        }

        return pickPage(pageName, args, options, loadCallback, callback);
    };

    return {
        getCurrentNode: getCurrentNode,
        update: updateCurrentNode,
        enqueue: navigate,
    };

    function navigate(handler) {
        if (navLock) {
            navQueue.push(handler);
            return false;
        }

        navLock = true;

        handler(function () {
            Stack.save();

            navLock = false;
        });

        return true;
    }

    function processNavigationQueue() {
        if (navQueue.length) {
            navigate(navQueue.shift());
            return true;
        } else {
            return false;
        }
    }

    function getCurrentNode() {
        return currentNode;
    }

    function updateCurrentNode() {
        var lastStackItem = Stack.getCurrent();
        current = lastStackItem[0];
        currentNode = lastStackItem[3];
    }

    function loadPage(pageName, args, options, callback, setupPickerMode) {
        navigate(function (unlock) {
            var oldNode = currentNode,
                pageManager = Pages.createManager(false);

            if (setupPickerMode) {
                setupPickerMode(pageManager);
            }

            var page = Pages.startGeneration(pageName, pageManager, args),
                restoreData = Stack.getCurrent(),
                restoreNode = restoreData && restoreData[3],
                restoreManager = restoreData && restoreData[2];

            if (!options.transition && pageManager.transition) {
                options.transition = pageManager.transition;
            }

            Pages.populateBackButton(page, oldNode || restoreNode);

            if (!current) {
                App.restore = null;
                document.body.appendChild(page);
                Pages.fire(pageManager, page, Pages.EVENTS.LAYOUT);
                updatePageData();
                finish();
            } else {
                Scroll.saveScrollPosition(currentNode);
                var newOptions = {};
                for (var key in options) {
                    newOptions[key] = options[key];
                }
                uiBlockedTask(function (unlockUI) {
                    Transitions.run(currentNode, page, newOptions, function () {
                        Pages.fixContent(page);
                        unlockUI();
                        finish();
                    });
                    Pages.fire(pageManager, page, Pages.EVENTS.LAYOUT);
                });
                //TODO: what if instant swap?
                updatePageData();
            }

            function updatePageData() {
                current = pageName;
                currentNode = page;
                Stack.push([pageName, args, pageManager, page, options]);
                if (oldNode && restoreManager) {
                    Pages.fire(restoreManager, oldNode, Pages.EVENTS.FORWARD);
                }
            }

            function finish() {
                Scroll.saveScrollStyle(oldNode);
                Pages.finishGeneration(pageName, pageManager, page, args);

                unlock();
                callback();

                if (oldNode && restoreManager) {
                    restoreManager.showing = false;
                    Pages.fire(restoreManager, oldNode, Pages.EVENTS.HIDE);
                }
                pageManager.showing = true;
                Pages.fire(pageManager, page, Pages.EVENTS.SHOW);
            }
        });

        if (!Pages.has(pageName)) {
            return false;
        }
    }

    function navigateBack(backPageName, callback) {
        if (Dialog.status() && Dialog.close() && !backPageName) {
            callback();
            return;
        }

        var stack = Stack.get().map(function (page) {
            return page[0];
        });

        if (!stack.length) {
            throw Error(
                backPageName + " is not currently in the stack, cannot go back to it"
            );
        }

        if (backPageName) {
            var index = -1;
            for (var i = stack.length - 1; i >= 0; i--) {
                if (stack[i] === backPageName) {
                    index = i;
                    break;
                }
            }
            if (index === -1) {
                throw Error(
                    backPageName + " is not currently in the stack, cannot go back to it"
                );
            }
            if (index !== stack.length - 2) {
                App.removeFromStack(index + 1);
            }
        }

        var stackLength = stack.length,
            cancelled = false;

        var navigatedImmediately = navigate(function (unlock) {
            if (Stack.size() < 2) {
                unlock();
                callback();
                return;
            }

            var oldPage = Stack.getCurrent();

            if (!Pages.fire(oldPage[2], oldPage[3], Pages.EVENTS.BEFORE_BACK)) {
                cancelled = true;
                unlock();
                callback();
                return;
            } else {
                Stack.pop();
            }

            var data = Stack.getCurrent(),
                pageName = data[0],
                page = data[3],
                oldOptions = oldPage[4];

            Pages.fire(oldPage[2], oldPage[3], Pages.EVENTS.BACK);

            Pages.fixContent(page);

            Pages.startDestruction(oldPage[0], oldPage[2], oldPage[3], oldPage[1]);

            Scroll.restoreScrollPosition(page);

            var newOptions = {};
            for (var key in oldOptions) {
                if (key === "transition") {
                    newOptions[key] =
                        Transitions.REVERSE_TRANSITION[oldOptions[key]] || oldOptions[key];
                } else {
                    newOptions[key] = oldOptions[key];
                }
            }

            uiBlockedTask(function (unlockUI) {
                Transitions.run(
                    currentNode,
                    page,
                    newOptions,
                    function () {
                        Pages.fixContent(page);
                        Scroll.restoreScrollStyle(page);
                        unlockUI();

                        oldPage[2].showing = false;
                        Pages.fire(oldPage[2], oldPage[3], Pages.EVENTS.HIDE);
                        data[2].showing = true;
                        Pages.fire(data[2], page, Pages.EVENTS.SHOW);

                        setTimeout(function () {
                            Pages.finishDestruction(
                                oldPage[0],
                                oldPage[2],
                                oldPage[3],
                                oldPage[1]
                            );

                            unlock();
                            callback();
                        }, 0);
                    },
                    true
                );
                Pages.fixContent(page);
                Pages.fire(data[2], page, Pages.EVENTS.LAYOUT);
            });

            current = pageName;
            currentNode = page;
        });

        if (cancelled || (navigatedImmediately && stackLength < 2)) {
            return false;
        }
    }

    function pickPage(pageName, args, options, loadCallback, callback) {
        var finished = false;
        loadPage(pageName, args, options, loadCallback, function (pageManager) {
            pageManager.restorable = false;
            pageManager.reply = function () {
                if (!finished) {
                    finished = true;
                    if (!pageManager._appNoBack) {
                        navigateBack(undefined, function () { });
                    }
                    callback.apply(App, arguments);
                }
            };
        });
    }

    // blocks UI interaction during some aysnchronous task
    // is not locked because multiple calls dont effect eachother
    function uiBlockedTask(task) {
        var taskComplete = false;

        var clickBlocker = document.createElement("div");
        clickBlocker.className = "app-clickblocker";
        document.body.appendChild(clickBlocker);
        clickBlocker.addEventListener(
            "touchstart",
            function (e) {
                e.preventDefault();
            },
            { passive: true }
        );

        task(function () {
            if (taskComplete) {
                return;
            }
            taskComplete = true;

            document.body.removeChild(clickBlocker);
        });
    }
})(window, document, App, App._Dialog, App._Scroll, App._Pages, App._Stack, App._Transitions);